@import "~wni-common-styles/mixins";
.chevronStyle {
    top: var(--GW-SPACING-XXS);
}
.accordionTitle {
    margin-left: var(--GW-SPACING-LG);
}
.accordionMainTitle {
    margin-right: calc(1.5 * var(--GW-SPACING-LG));
    font-size: var(--GW-FONT-SIZE-XXL);
}
.renderAccordionHeader {
    .title {
        font-size: calc(3 * var(--GW-SPACING-SM));
    }
}
.titleSeparator {
    margin: var(--GW-SPACING) 0;
}
.cardBodyMainMargins {
    margin: 20px 0;
    [class$='Accordion__header'] {
        font-family: $LocatorLight !important;
    }
}
.cardBodyMargins {
    margin: 0;
    margin-left: 20px;
}
.gridMargin {
    --gw-quote-change-grid-width: 99%;
    margin-left: calc(3 * var(--GW-SPACING));
    width: var(--gw-quote-change-grid-width);
    
    .rootLabel{
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
}
.gridColumn {
    text-align: right;
    &:last-child {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
}
.gridTitle {
    border-bottom: calc(0.25 * var(--GW-SPACING-XXS)) solid var(--GW-COLOR-BACKGROUND-1-ACTIVE);
    padding-bottom: var(--GW-SPACING-XS);

    &:first-child {
        padding-left: var(--GW-SPACING-XXL);
    }

    &:not(:first-child) {
        text-align: right;
    }
}