@import "~wni-common-styles/mixins";

/* :root {*/
/*   --gw-cost-box-padding: 20px;*/
/*   --gw-box-padding: 25px;*/
/*   --gw-border-solid: 2px;*/
/*   --gw-divider-margin: 20px;*/
/*   --gw-divider-padding: 20px;*/
/*   --gw-divider-border: 1px;*/
/*   --gw-page-section-header-row-padding-top: 8px;*/
/*   --gw-titles-subtitle-offset: 16px;*/
/*   --gw-billing-input-field-thin-height: 20px;*/
/* }*/

/* .gwProducerCodeDivider {*/
/*   display: inline-block;*/
/*   border-left: solid var(--gw-divider-border) var(--gw-grey-light-30-color);*/
/*   padding-right: var(--gw-divider-padding);*/
/*   margin-left: var(--gw-divider-margin);*/
/*   margin-bottom: var(--gw-divider-margin);*/
/* }*/

/* .gridBorder_ext {*/
/*   height: 160px;*/
/* }*/

.inputFieldThin {
  height: var(--gw-billing-input-field-thin-height);
  span {
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
  div {
    div {
      font-weight: bold;
      font-size: 18px;
      width: 150px;
    }
  }
}

.inputFieldThin_ext {
  div {
    div {
      font-size: 24px;
      font-weight: bold;
      margin-top: -10px;
    }
  }
}

.gridOffset + span {
  margin-left: 26px;
  font-weight: bold;
}

.inputFieldThinPremiumDivder {
  font-weight: bold;
  font-size: 14px;
  border-top: 1px solid #ccc;
  margin-top: -1px;
}

.labelWidth{
  --gw-label-width: 230px;
  width: var(--gw-label-width);
}

.totalDue {
  --gw-section-separator-width: 1px;
  font-size: var(--font-l);
}

.premiumDivder {
  --gw-section-separator-width: 1px;
  font-size: var(--font-l);
  border-top: var(--gw-section-separator-width) solid var(--gw-white-opaque-5);
}
/*set up the Amount font*/
.newpremiumAmt {
  font-size: 30px;
  font-weight: bold;
}
.inputFieldNormal {
  font-size: var(--font-1);
  font-weight: normal;
}
.gridBorder {
  border: 1px solid var(--gw-grey-light-30-color);
  padding: var(--gw-cost-box-padding);
}

.endorsementPolicyPeriodDate{
  div[class*='datePicker'] {
    --gw-icon-padding: 20px;
    padding-top: var(--gw-icon-padding);
    font-size: var(--font-xlg);
  }
  text-align: center;
}

.iconStyle {
  font-size: 20px;
  vertical-align: sub;
}

.policyChangeDateContainerStyle {
  align-items: center;
  align-content: center;
}

#PACostAndPremiumCurrentPremium {
  height: 40px;
  line-height: 40px;
}
.costAndPremiumGridRight{
  max-width: 400px;
  min-width: 250px;
  width: 35%;
}

.over>div>div>span {
  color: $Success !important;
}

.below>div>div>span {
  color: $Error !important;
}

.infoContent {
  padding: var(--GW-SPACING);
}
