@import "~wni-common-styles/mixins";
/* .clause {*/
/*     --clause-background-color: var(--gw-white);*/
/*     --clause-padding: 24px;*/

/*     @include gw-breakpoint-down(tabletPortrait) {*/
/*         --clause-padding: 0.25rem;*/
/*     }*/

/*     background-color: var(--clause-background-color);*/
/*     padding: var(--clause-padding);*/
/* }*/

.clauseNoPadding {
    padding: 0;
}

.clauseAndTermSpacing {
    --gw-clause-component-spacing: 15px;
}

/* Not Editable Styles*/
.readOnlyGrid {
    --clause-margin: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    margin: var(--clause-margin) var(--clause-margin) 0 var(--clause-margin);

    &:not(:last-child) {
        --read-only-clause-margin: 16px;
        --read-only-clause-boder-size: 1px;
        margin-bottom: var(--read-only-clause-margin);
        border-bottom: var(--read-only-clause-boder-size) solid var(--gw-grey-light-30-color);
    }
}

.readOnlyGridSlotLeft {
    grid-area: left;
}

.readOnlyGridSlotRight {
    grid-area: right;
}

.readOnlyGridAmount {
    --read-only-clause-amount-margin: 5px;
    margin-left: var(--read-only-clause-amount-margin);
}