@import "~wni-common-styles/mixins";

:root {
  --gw-menubar-anchor-padding: 8px 25px;
  --gw-menubar-height: 34px;
}

.menuBar {
    --nav-item-icon-margin-x: var(--GW-SPACING-SM);
    background: $gw-blue-light-2;
    a {
      color: $white;
      padding: var(--gw-menubar-anchor-padding);
      height: var(--gw-menubar-height);
      text-decoration: none;
      line-height: var(--gw-menubar-height);
      font-family: $LocatorRegular;
      &:hover {
        background-color: var(--gw-black-opaque-1);
        color: $white;
        text-decoration: none;
      }
    }
    a.activeNavItem {
      background-color: var(--gw-white-opaque-3);
    }
    .navIcon {
      margin-right: var(--nav-item-icon-margin-x);
    }
  }

  @media print {
    .menuBar {
      display: none;
    }
  }