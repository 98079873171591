:root {
  --gw-header-page-bottom-line: 2px;
  --gw-space-for-padding: 3px;
  --line-padding-left: 16px;
  --gw-button-padding: 10px;
  --gw-activity-field-line-height: 25px;
  --gw-container-height: 4.4em;
  --gw-container-width: 1.2em;
  --gw-activity-padding-left: 90px;
  --gw-complete-margin-right: .25rem;
  --gw-activities-complete-button-padding: 1rem;
}

.addActivityGrid {
  padding-left: var(--gw-activity-padding-left);
}

.completeButton {
  margin-right: var(--gw-complete-margin-right);
}

.cssNoOfDueDays {
  color: var(--GW-COLOR-TEXT-ACCENT-HOVER);
}

.gwPageHeader {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.gwPageHeaderRightPanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gwSpaceFor {
  padding-left: var(--gw-space-for-padding);
  padding-right: var(--gw-space-for-padding);
}

.buttonMarginStyle {
  margin-left: var(--gw-space-for-padding);
}

.gwRowLayout {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-content: flex-start;
}

.gwNoMessage {
  font-size: var(--GW-FONT-SIZE);
  font-weight: normal;
  margin-top: var(--gw-noMessage-margin-top);
  background-color: transparent;
}

.gwRowLayoutItem {
  display: flex;
}

.gwColumnLayout {
  margin-right: var(--GW-SPACING-SM);
  margin-left: var(--GW-SPACING-SM);
}

.addNewActivityContainerStyle {
  padding-left: var(--GW-SPACING);
  padding-right: var(--GW-SPACING);
  min-height: var(--gw-container-height);
  min-width: var(--gw-container-width);

  .spanStyle {
    width: 100%;
    text-align: right;
    padding-left: var(--gw-header-padding);
  }

  .gwButtonStyle {
    padding: var(--gw-header-padding);
  }
}

.activityIcon {
  --gw-activities-icon-padding: 2px;
  --gw-activities-icon-position: 5px;
  padding-left: var(--gw-activities-icon-padding);
  position: relative;
  top: var(--gw-activities-icon-position);
}

.removeLinkStyle {
  text-decoration: none;
  color: var(--GW-COLOR-FOCUS-1-HOVER);
  [class*="text"] {
    &:hover {
      text-decoration: none;
    }
  }
}
.gwHideCompleteButton {
  display: none;
}

div[class*="rt-tr-group"] {
  div[class*="rt-tr"]:not(:only-child) {
    .gwHideCompleteButton {
      display: block;
    }
  }
}

.field {
  text-transform: uppercase;
  font-size: var(--GW-FONT-SIZE-XS);
  color: var(--GW-COLOR-TEXT-1);
  line-height: var(--gw-activity-field-line-height);
}

.fieldValue {
  font-size: var(--GW-FONT-SIZE);
}

.gwActivityContent {
  --gw-activity-expander-content-padding: 15px;
  --gw-activity-expander-content-border: 1px;

  padding-top: var(--gw-activity-expander-content-padding);
  border-top: var(--gw-activity-expander-content-border) solid var(--GW-COLOR-TEXT-3-HOVER);
}

.gwActivityTableNotesSeparator {
  --gw-activity-expander-content-notes-separator-margin: 25px;
  --gw-activity-expander-content-notes-separator-border: 1px;

  margin-top: var(--gw-activity-expander-content-notes-separator-margin);
  border-top: var(--gw-activity-expander-content-notes-separator-border) solid var(--GW-COLOR-TEXT-3-HOVER);
}
div[class*="rt-th"]:not(:only-child) {
  div[class*="rt-resizable-header-content"] {
    overflow: visible;  
  }
}