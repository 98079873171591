:root {
  --gw-agent-container-margin: 0 auto;
  --gw-agent-container-max-width: 90em;
  --gw-contactagent-checkicon-height: 5em;
  --gw-contactagent-checkicon-width: 5em;
  --gw-contactagent-checkicon-margin: 3em auto;
}

.agentContainer {
  margin: var(--gw-agent-container-margin);
  max-width: var(--gw-agent-container-max-width);

  .confirmationCheckIcon {
    height: var(--gw-contactagent-checkicon-height);
    width: var(--gw-contactagent-checkicon-width);
    line-height: var(--GW-LINE-HEIGHT-XXXL);
    color: var(--GW-COLOR-SUCCESS);
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    border-radius: 50%;
    margin: var(--gw-contactagent-checkicon-margin);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .confirmationIcon {
    --gw-fnol-confirmation-icon: 6rem;
    line-height: inherit;
    font-size: var(--gw-fnol-confirmation-icon);
  }

  .paraBottom {
    margin-bottom: 0;
  }

  .agentTitle {
    text-align: center;
  }

  .printConfirmationDiv {
    text-align: center;
  }

  .contactAgentPara {
    text-align: center;
  }

  .claimReference {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    text-align: center;
  }

  .btnClass {
    --gw-btn-margin-top: 10px;
    text-align: center;
    margin-top: var(--gw-btn-margin-top);
  }
}
