.redText {
  color: #CA2921 !important;
}

.fontWeightNormal {
  font-weight: normal !important;
  color: #CA2921;
}

.flexAuto {
  flex: auto;
}