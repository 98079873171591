
@import "~wni-common-styles/mixins";

:root{
    --gw-quoteContainer-margin:0 auto;
    --gw-quoteContainer-max-width: 50em;
    --gw-buttonFooter-width: 100%;
    --gw-buttonFooter-margin-top: 15px;
    --gw-buttonFooter-margin-left: 1%;
}

.quoteContainer{
    margin: var(--gw-quoteContainer-margin);
    max-width: var(--gw-quoteContainer-max-width);
}
.policyDetailsContainer{
    border-bottom: 2px solid $borderColor;
    margin-bottom: var(--gw-buttonFooter-margin-top);
    padding-bottom: var(--gw-buttonFooter-margin-top);
}
.buttonFooter{
    width: var(--gw-buttonFooter-width);
    margin-top: var(--gw-buttonFooter-margin-top);
}
