@import "~@jutro/theme/assets/sass/helpers";
@import "~wni-common-styles/mixins";
.accountbilling {
    .boldText {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }

    .largeTextFont {
        --field-component-control-readonly-font-size: var(--GW-FONT-SIZE-LG);
        font-size: var(--fieldComponent-control-readonly-font-size);
    }

    .billingSummaryGridBorder {
        border-right: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-BACKGROUND-3);
    }

    .billingSummarySeparator {
        --gw-billing-summary-separator-width: 95%;
        width: var(--gw-billing-summary-separator-width);
        margin-top: var(--GW-SPACING-SM);
    }

    .regularText {
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    }

    .billingCurrencyRightAlign {
        display: grid;
        grid-template-columns: 3fr 1fr;
        width: 100%;
        text-align: right;
        padding-right: var(--GW-SPACING-XXXL);
    }

    .billingProducerCodesTitle {
        --gw-billing-sub-title-padding: 0;
        font-size: var(--GW-FONT-SIZE-MD);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        text-align: left;
        padding: var(--gw-billing-sub-title-padding);
        background-color: transparent;
        margin-bottom: var(--GW-SPACING-SM);
    }

    .billingPrimaryPayerTitle {
        --gw-billing-sub-title-padding: 0;
        font-size: var(--GW-FONT-SIZE-MD);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        text-align: left;
        padding: var(--gw-billing-sub-title-padding);
        background-color: transparent;
        margin-bottom: var(--GW-SPACING-SM);
    }

    .billingSummaryGrid {
        padding: var(--GW-SPACING-SM);
    }

    .payerContainer {
        margin-bottom: var(--GW-SPACING-XL);
    }

    .producerCodesBlock {
        margin-bottom: var(--GW-SPACING-SM);
    }

    .accountBillingIcon {
        font-size: var(--GW-FONT-SIZE-XL);
        padding: var(--GW-SPACING-SM) var(--GW-SPACING) 0 0;
    }

    .accountBillingDelinquent {
        color: var(--GW-COLOR-ALERT-WARNING);
    }

    .accountBillingGoodStanding {
        color: var(--GW-COLOR-BRAND-3);
    }

    .automaticPaymentsIcon {
        --automatic-payments-icon-border-radius: 100%;

        border: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-BACKGROUND-3);
        padding: var(--GW-SPACING-SM);
        border-radius: var(--automatic-payments-icon-border-radius);
        background-color: var(--GW-COLOR-BACKGROUND-3);
        height: var(--GW-SPACING-XXXL);
        width: var(--GW-SPACING-XXXL);
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .automaticPaymentsIconCheck {
        --automatic-payments-icon-check-width: 1.75rem;
        --automatic-payments-icon-check-height: 1.75rem;
        --automatic-payments-icon-check-border-color: var(--GW-COLOR-BACKGROUND-1);
        --automatic-payments-icon-check-border-radius: 50%;
        --automatic-payments-icon-check-position-right: -0.5rem;
        --automatic-payments-icon-check-position-bottom: -0.5rem;

        position: absolute;
        width: var(--automatic-payments-icon-check-width);
        height: var(--automatic-payments-icon-check-height);
        border: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BACKGROUND-1);
        background-color: var(--gw-lime-green);
        color: var(--GW-COLOR-BACKGROUND-1);
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: var(--automatic-payments-icon-check-border-radius);
        box-sizing: content-box;
        right: var(--automatic-payments-icon-check-position-right);
        bottom: var(--automatic-payments-icon-check-position-bottom);
        font-size: var(--font-xxs);
    }

    .automaticPaymentsText {
        height: var(--GW-SPACING-LG);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        margin-top: var(--GW-SPACING-XXS);
    }

    .setupAutomaticPayment {
        margin-top: var(--GW-SPACING-LG);
        margin-left: var(--GW-SPACING-XS);
        font-weight: var(--font-weight-normal);
    }

    .chevronStyle {
        color: var(--GW-COLOR-TEXT-2);
    }

    .accordionTitle {
        background-color: transparent;
        color: var(--GW-COLOR-TEXT-2);
        cursor: pointer;
        margin-left: var(--GW-SPACING-XXL);
        margin-top: var(--GW-SPACING);
    }

    .paymentInstrumentSummary {
        --gw-payment-instrument-summary-grid-columns-tablet: 1fr;
        margin-top: var(--GW-SPACING-LG);

        @include gw-breakpoint-only(phone) {
            grid-template-columns: var(--gw-payment-instrument-summary-grid-columns-tablet) var(--gw-payment-instrument-summary-grid-columns-tablet) !important;
        }
    }

    .makeAPayment {
        margin-top: var(--GW-SPACING-XXL);
    }

    .billingPageProducerCode,
    .billingPagePrimaryPayer, 
    .billingPageTabsContainer {
        padding: var(--GW-SPACING-XL) var(--GW-SPACING-XL) var(--GW-SPACING-XXL);
    }

    .invoiceStreamTitle {
        font-size: $font-16;
        font-family: $LocatorLight;
        font-weight: 600;
        color: $NeutralUltraDark;
    }

    .customerPaymentSummaryStyle {
        color: var(--GW-COLOR-TEXT-2);
    }

    .policiesAffectedTitle {
        --gw-billing-policies-affected-title-padding: 0;
        --gw-billing-policies-affected-title-line-height: var(--GW-SPACING);
        font-size: var(--GW-FONT-SIZE);
        text-align: left;
        padding: var(--gw-billing-policies-affected-title-padding);
        background-color: transparent;
        line-height: var(--gw-billing-policies-affected-title-line-height);
        color: var(--GW-COLOR-TEXT-2);
        justify-content: left !important;
        margin-bottom: var(--GW-SPACING-SM);
    }

    .totalDueTitle {
        text-transform: uppercase;
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }

    .totalDueSeparator {
        margin: var(--GW-SPACING-SM) 0;
    }

    .setupAutomaticPaymentTitle {
        font-size: $font-14;
        text-align: left;
        padding: 0;
        background-color: transparent;
        color: $NeutralUltraDark;
        white-space: normal;
        align-items: flex-start !important;
        height: auto;
    }

    .paymentSetupSection {
        margin-top: var(--GW-SPACING-XXL);
        margin-left: var(--GW-SPACING-LG);
    }

    .paymentSetupPremiumLabel {
        text-align: right;
    }

    .paymentSetupPremiumValue {
        text-align: left;
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }

    .paymentScheduleText {
        text-align: left;
        justify-content: left !important;
        background-color: transparent;
        color: var(--GW-COLOR-TEXT-2);
        font-size: var(--GW-FONT-SIZE);
    }

    .detailedSchedule {
        border: none;
        color: $SecondaryLink;
        text-decoration: none;
        text-align: right;
        justify-content: right;
        svg{
            font-size: 25px;
            margin-left: 5px;
        }
        &:hover, &:active{
            text-decoration: underline;
            background: transparent;
        }
    }

    .detailedScheduleTable {
        margin-top: var(--GW-SPACING-LG);
    }

    .paymentSetupFooter {
        text-align: right;
    }

    .cancelSetupPayment {
        --gw-cancel-setup-payment-margin-right: 0.25em;
        margin-right: var(--gw-cancel-setup-payment-margin-right);
    }

    .setupPaymentConfirmWrapper {
        padding-top: var(--GW-SPACING-XXL);
        padding-bottom: var(--GW-SPACING-XXXL);
    }

    .setupPaymentConfirmContainer {
        --gw-setup-payment-confirm-border-radius: 3px;
        border: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-BORDER);
        border-radius: calc(1.5 * var(--GW-BORDER-WIDTH));
        padding: var(--GW-SPACING-XXL);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        line-height: var(--GW-SPACING-XL);
        color: var(--GW-COLOR-TEXT-2);
        /* text-align: center;*/
    }

    .setupPaymentSuccessMessage, .setupPaymentSuccessPhone {
        font-family: $LocatorLight;
        font-weight: 600;
        color: $Error;
        background-color: transparent;
        font-size: $font-16;
    }

    .setupPaymentSuccessValue {
        font-family: $LocatorLight;
        font-weight: 600;
        background-color: transparent;
        font-size: $font-14;
        color: $NeutralUltraDark;
    }
    .autoPayEnrollmentDocument{
        font-family: $LocatorLight;
        font-weight: 600;
        background-color: transparent;
        font-size: $font-14;
        color: $SecondaryLink;
    }
    .billingNoData {
        font-size: var(--GW-FONT-SIZE);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        text-align: left;
        padding: 0;
        margin-top: var(--GW-SPACING-XXXL);
        background-color: transparent;
    }
    .currencyContainer {
      div[class*="fieldComponent"] {
        padding-bottom: 0;
      }
    }
    
    .headerSep {
        margin: var(--GW-SPACING) 0 0 0; 
    }

    .gwTitleStyle {
        padding: var(--GW-SPACING-SM);
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BORDER);
    }

    .gwPolicyOwnedListTableContainer {
        margin: var(--GW-SPACING-XL) 0;
    }
    .gwPolicyUnOwnedListTableContainer {
        margin: var(--GW-SPACING-XL) 0;
    }
    .tabBillingContent {
        margin-top: var(--GW-SPACING-XL);
    }
    .redFont {
        color: #CA2921;
    }
    .infoContaner {
        /* text-align: center;*/
    }
}