.alertIcon {
  color: #CA2921;
  margin-right: 3px;
}

.fontWeightNormal {
  font-weight: normal !important;
  color: #CA2921;
}

.flexAuto {
  flex: auto;
}