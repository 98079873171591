:root {
    --gw-gateway-page-height: 400px;
    --gw-header-page-bottom-line: 2px;
    --gw-header-row-margin-bottom: 10px;
    --gw-header-column-margin-right: 10px;
    --gw-header-column-margin-left: 10px;
    --gw-iconMargin-margin-top: 20px;
    --gw-display-nomessage-margin-top: 8px;
    --gw-search-column-layout-width: 37.5%;
    --gw-display-nomessage-margin-left: 6px;
    --gw-column-layout-width: 23rem;
}

.gwPageHeader {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    border-bottom: var(--gw-header-page-bottom-line) solid var(--gw-grey-2);
}

.gwPageHeaderRightPanel {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.gwRowLayout {
    display: flex;
    flex-flow: row;
    align-items: stretch;
    align-content: flex-start;
    margin-bottom: var(--gw-header-row-margin-bottom);
}

.gwRowLayoutItem {
    display: flex;
}
.gwColumnLayout{
    margin-left: var(--gw-header-column-margin-left);
    width: var(--gw-column-layout-width);
    margin-right: var(--line-height-xs);

    & > div {
        grid-template-columns: auto auto;
        font-size: var(--base-font-size);
        font-weight: normal;
    }
}

.gwRowSearchLayout {
    width: var(--gw-search-column-layout-width);
    margin-left: var(--gw-header-column-margin-left);
}

.iconMargin {
    margin-top: var(--gw-iconMargin-margin-top);
}

.gwDisplayNoMessage {
    font-size: var(--base-font-size);
    font-weight: normal;
    margin-top: var(--gw-display-nomessage-margin-top);
    margin-left: var(--gw-display-nomessage-margin-left);
    background-color: var(--gw-white);
}

.currencyContainer {
    div[class*="fieldComponent"] {
        padding-bottom: 0;
    }
}

.gwAccountClaimsLinkContent {
    text-decoration: none;
    color: var(--gw-blue-light-8);
}

.currencyColumn {
    padding-bottom: 0;
}