.productListImg{
    width: 100%;
    /* min-width: 500px;*/
    height: 400px;
    background-image: url('./src/productList.PNG');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
}

.configImg{
    width: 100%;
    /* min-width: 500px;*/
    height: 400px;
    background-image: url('./src/config.PNG');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
}