
:root {
	--gw-cancellation-padding-right: 10px;
	--gw-cancellation-padding-left: 10px;
	--gw-cancellation-border: 1px;
	--gw-cancellation-title-border-bottom: 2px;
	--gw-cancellation-do-not-Cancel: 20px;
	--gw-cancellation-info-mar-bot: 40px;
	--gw-cancellation-container: 80px;
	--gw-cancellation-Title-pad-bot: 16px;
	--gw-cancellation-Title-mar-bot: 32px;
	--gw-cancellation-mar-right: 5px;
	--gw-cancellation-mar-left: 5px;
	--gw-info-message-padding:16px;
	--gw-infoLabelTitle-padding: 0 1px 5px 0;
}

.cancellationLabel {
	color: var(--gw-grey-dark-90-color);
	font-size: var(--base-font-size);
	margin-right: var(--gw-cancellation-mar-right);
}

.cancellationLabelLink {
	text-decoration: none;
	color: var(--gw-blue-light-8);
	font-weight: var(--font-weight-bold);
}

.cancellationLeft {
	display: inline-block;
	padding-right: var(--gw-cancellation-padding-right);
}

.cancellationRight {
	border-left: var(--gw-cancellation-border) solid var(--gw-grey-light-1);
	padding-left: var(--gw-cancellation-padding-left);
	display: inline-block;
}

.donnotCancel {
	padding-right: var(--gw-cancellation-do-not-Cancel);
}

.cancellationTitle {
	font-size: var(--font-xxxl);
	font-weight: var(--font-weight-regular);
	line-height: var(--line-height-s);
	color: var(--gw-grey-dark-90-color);
}

.infoMessage {
	background-color: var(--gw-blue-light-9);
	border-color: var(--gw-blue-light-10);
	color: var(--gw-grey-dark-90-color);
	padding: var(--gw-info-message-padding);
	margin-bottom: var(--gw-cancellation-info-mar-bot);
	span {
		font-size: var(--font-xl);
	}
}

.notestextareaBox {
	margin-bottom: var(--gw-cancellation-info-mar-bot);
}

.cancellationContainer {
	padding-top: var(--gw-cancellation-container);
	justify-content: flex-end;
	button {
		float: right;
		margin-left: var(--gw-cancellation-mar-left);
	}
}

.infoLabelTitle {
	background: none;
	padding: var(--gw-infoLabelTitle-padding);
	margin: 0;
	span {
		font-size: var(--font-xlg);
		line-height: var(--line-height-s);
		color: var(--gw-dark-blue-1);
		display: block;
		background: none;
		border-radius: none;
	}
}

.infoLabelTitleDiv {
	border-bottom: var(--gw-cancellation-title-border-bottom) solid var(--gw-grey-2);
	padding-bottom: var(--gw-cancellation-Title-pad-bot);
	margin-bottom: var(--gw-cancellation-Title-mar-bot);
	color: var(--gw-dark-blue-1);
}
.cancellationMessage {
	[class*=digitalFieldMessage] {
		display: none;
	}
}
.gwErrorText {
	display: flex;
    flex-direction: row-reverse;
    color: var(--gw-red-3);
}

