
.gwAlert{
	--gw-uwissues-offering-name-padding-top: 2rem;
	--gw-uwissues-offering-name-border: 1px solid var(--gw-black);
	--gw-alert-padding: 16px;
	--gw-waring-padding-right: 16px;
	--gw-uwissues-resolution-padding: 1em;
	--gw-uwissues-resolution-indent: 5em;

	background-color: var(--gw-orange-6);
	border: 1px solid var(--gw-orange-7);
	padding: var(--gw-alert-padding);
	display: flex;

	.gwWarning {
		color: var(--gw-orange-8);
		padding-right: var(--gw-waring-padding-right);
		font-size: var(--font-xl);
	}
	.gwAlertContent {
		width: 100%;
	}
	.alertOptions {
		padding: var(--gw-uwissues-resolution-padding);
		padding-left: var(--gw-uwissues-resolution-indent);
		margin-bottom: 0;
	}
	.gwUwIssuesTable{
		width: 100%;
		border-collapse: collapse;
	}
	.gwUwIssueOfferingName{
		padding-top: var(--gw-uwissues-offering-name-padding-top);
		border-bottom: var(--gw-uwissues-offering-name-border);
	}
	.gwIssueDescription {
		font-weight: var(--font-weight-bold);
	}
	.referToUWFormActions {
		display: flex;
		justify-content: space-between;
	}
	.UWFormActions {
		display: flex;
		justify-content: flex-end;
	}
	.approve {
		color: var(--gw-qqdriver-terms-color);
		cursor: pointer;
	}
	.UWFormConfirmStyle {
		font-weight: var(--font-weight-bold);
	}
}