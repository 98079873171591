/* Not Editable Styles*/
.readOnlyContainer {
    --clause-margin: 20px;
    margin: var(--clause-margin) var(--clause-margin) 0 var(--clause-margin);

    &:not(:last-child) {
        --read-only-clause-margin: 16px;
        --read-only-clause-boder-size: 1px;
        margin-bottom: var(--read-only-clause-margin);
        border-bottom: var(--read-only-clause-boder-size) solid var(--gw-grey-light-30-color);
    }
}

