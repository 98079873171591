@font-face {
  font-family: 'Source Sans 3';
  font-weight: 100 1000;
  font-style: normal;
  font-stretch: normal;
  src: url('#{$source-sans-3-root}/SourceSans3-VariableFont_wght.ttf')
    format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans 3';
  font-weight: 100 1000;
  font-style: italic;
  font-stretch: normal;
  src: url('#{$source-sans-3-root}/SourceSans3-Italic-VariableFont_wght.ttf')
    format('truetype');
  font-display: swap;
}
