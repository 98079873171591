
:root {
	--gw-document-title-border: 2px;
	--gw-document-padding-right: 20px;
	--gw-document-trash-width: 30px;
    --gw-document-trash-height: 22px;
    --gw-document-title-right-grow: 8;
	--gw-document-title-left-grow: 1;
	--gw-document-title-padding-bottom: 10px;
	--gw-document-title-span-width: 86%;
	--gw-documents-margin-bottom: 4px;
	--gw-documents-margin-right: 3px;
}

.documentTitle {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	padding-bottom: var(--gw-document-title-padding-bottom);
	span {
		width: 100%;
	}
}
.documentName {
	justify-content: left !important;
	span:hover {
		text-decoration: none;
	}
}
.fileIcon{
	margin-right: var(--gw-documents-margin-right);
}

.trashLink {
	span {
		width: var(--gw-document-trash-width);
		height: var(--gw-document-trash-height);
		border: 1px solid var(-gw-black-2);
		background: var(-gw-black-2);
		display: block;
		font-size: var(--font-xxs);
		color: var(-gw-black-2);
		text-align: center;
	}
}

.documentTitleRight {
	display: flex;
	justify-content:space-between;
	align-items: right;
	flex: 1;
	.uploadbutton {
		padding-right: var(--gw-document-padding-right);
		min-width: auto;
	}
}

.noDocumentsText{
    background: transparent;
    color: var(--gw-black);
    font-size: var(--font-ml);
    margin-bottom: var(--gw-documents-margin-bottom);
}
.navButtons{
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: var(--gw-wizard-page-buttons-bar-padding);
    border-top: 1px solid var(--gw-grey-light-30-color);
    margin-top: var(--gw-wizard-page-buttons-bar-margin);
}
.typeFilter{
	align-items: center !important;
	width: 30em;
	margin-left: var(--GW-LAYOUT-10);
}
