@charset "UTF-8";
/* initialize the helpers here*/
/* ....*/
/* end initialization*/

@import "../generated/styles/style-tools.scss";

/* customize the styles here*/
/* ...*/
/* end customziation*/

@import "../generated/styles/styles.scss";
@import "~gw-capability-gateway-react/gateway-styles";

:root {
  --gw-producer-engage-container-max-width: 90em;
  --gw-producer-engage-container-width: 90%;
  --gw-producer-table-icon-spa: 4px;
  --gw-main-container-padding: 160px;
  --gw-sub-title-padding-bottom: var(--GW-SPACING);
  --gw-sub-title-border: 2px;
  --gw-tablet-sidebar-width: 20rem;
}
html {
  --gw-header-image: url("../../public/wni-images/branding/wni/AgentsOnlineHeader.svg");
  --gw-header-mobile-image: url("../../public/wni-images/branding/wni/AgentsOnlineW.svg");
  --gw-header-mm-image: url("../../public/wni-images/branding/mm/MMI-desktop-white.png");
  --gw-header-mm-mobile-image: url("../../public/wni-images/branding/mm/MMI-mobile-white.png");
  --gw-header-uic-image: url("../../public/wni-images/branding/uic/UIC-desktop-white.png");
  --gw-header-uic-mobile-image: url("../../public/wni-images/branding/uic/UIC-mobile-white.png");
  --gw-header-userlogo-image: url("../../public/wni-images/branding/wni/WNPersonLight.svg");
  --gw-facebook-logo-image: url("../../public/wni-images/branding/wni/FacebookW.svg");
  --gw-linkedin-logo-image: url("../../public/wni-images/branding/wni/LinkedInW.svg");
  --gw-twitter-logo-image: url("../../public/wni-images/branding/wni/TwitterW.svg");
  font-size: var(--gw-basic-font);
  height: 100%;
}
body {
  /*--gw-header-image: url("../../public/branding/logo-version-agents.svg");*/
  /*--gw-header-mobile-image: url("../../public/branding/logo-mobile.svg");*/
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  > div#root {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

.producerEngageContainer {
  background-color: var(--GW-COLOR-BACKGROUND-2);
}
.inlineWithIndent {
  >* {
    display: inline-block;
    &:not(:last-child) {
      margin-right: var(--GW-SPACING);
    }
  }
}
.gwTitle {
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
}

.gwSubtitle {
  font-size: var(--GW-FONT-SIZE-XL);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  line-height: var(--GW-LINE-HEIGHT-XL);
  padding-bottom: var(--gw-sub-title-padding-bottom);
  border-bottom: var(--gw-sub-title-border) solid var(--GW-COLOR-BORDER);
}

.globalizationChooser {
  padding: 0 var(--GW-SPACING);
  select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
  }
  span {
    visibility: hidden;
  }
}
table[class*='tableElement'] {
  a {
    text-decoration: none;
    color: var(--GW-COLOR-LINK-1);
  }

  i {
    margin-right: var(--gw-producer-table-icon-spa);
  }
}
.loadingPageMask{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  .loadingMask{
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.2;
  }
  .jut__Loader__loader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
}

/* theme*/
/* $fonts-root: "../assets/fonts";*/
@import '../assets/fonts/fonts.scss';
@import '~wni-common-styles';


.jut__ModalNext__modal {
  --gw-modal-max-height: 85vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}

.jut__FieldLabel__left {
  .jut__FieldLabel__fieldLabel {
    margin-right: calc(var(--GW-SPACING-LG) / 2);
  }
}
