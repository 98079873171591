
.cancellationEffectiveDate {
	font-weight: var(--font-weight-regular);
	font-size: var(--font-xxl);
}

.cancellationRefundAmount {
	--fieldComponent-control-readonly-font-weight: var(--font-weight-regular);
	--fieldComponent-control-readonly-font-size: var(--font-xxl);
}
