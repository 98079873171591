.flex2 {
  flex: 2;
}

.flex > div:nth-child(2) > div > div > div {
  display: flex;
}

.flexEnd {
  justify-content: flex-end;
}

.bottomSpace {
  margin-bottom: var(--GW-LAYOUT-6);
}

.docAccordion > div > div:last-child {
  border-bottom: 0px !important;
}