.gwRowLayout {
    margin-bottom: var(--GW-SPACING-XL);
}


.gwProducerCodeDivider {
    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));
    border-left: solid var(--GW-COLOR-BORDER) var(--GW-BORDER-WIDTH-THIN);
}

.gwAccountsListTableContainer {
    padding: var(--GW-SPACING-XL) var(--GW-SPACING-XL) var(--GW-SPACING-XXL);
}

.gwTitleStyle {
    padding-bottom: var(--GW-SPACING-SM);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BORDER);
  }