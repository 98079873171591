.driver-cov-checkbox {
    align-items: start;
    /* margin-top: 7px;*/
    margin-bottom: 10px !important;
    .jut__FieldComponent__controls {
        order: 1;
        flex: 0;
    }
    .jut__FieldLabel__left {
        order: 2;
        /* margin-left: 10px;*/
    }
    .jut__TooltipIcon__left {
        order: 3;
        top: -5px;
    }
}