/* see QuoteClauseTable.module.scss*/
.quoteTableHeaderSection {
    --gw-quote-table-header-section-spacing: 22px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--GW-COLOR-BACKGROUND-1-ACTIVE);
    padding: var(--gw-quote-table-header-section-spacing) 0 var(--gw-quote-table-header-section-spacing) 0;
    position: relative;

    &:not(:last-child) {
        border-right: none;
    }
}
.premiumFont20{
    div{
        font-size: 36px;
        font-weight: 600;
    }
}

.waitOrBindMessageStyle{
    padding: 0px var(--GW-SPACING-10);
    background-color: #FDF2F3;
    border-radius: var(--GW-BORDER-RADIUS);
    text-align: justify;
    
}

.virtualInspectionHeaderStyle{
    font-size: 1rem;
    font-weight: bolder;
    text-align: center;
}