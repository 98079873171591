@import "~wni-common-styles/mixins";

.downloadDocumentRight {
	display: flex;
	justify-content:space-between;
	align-items: right;
	flex: 1;
	.uploadbutton {
		padding-right: var(--gw-document-padding-right);
		min-width: auto;
	}
}
.documentTitle{
	overflow: hidden;
}
.btn-md{
	height:  $fieldHeight;
	float: right;
}
.download-link{
	word-break: break-all;
	height: auto;
	padding: 3px 0;
}

.new-line{
	white-space:pre-line;
}
.link-span{
	padding-right: 5px;
}
.agentQuotesMargin{
	margin-top: 10px;
	margin-bottom: 10px;
}

.totalCost{
	border-top-style: solid;
	border-top-width: 1px;
	border-top-color: $PrimaryDark;
}
