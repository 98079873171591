/*extracted from UnderwritingComponent.module.scss*/

.notTakenTitle {
	text-align: left;
}

.gwReferToUnderwriterForm {
	display: flex;
}

.gwReferralButtons {
	display: flex;
	justify-content: flex-end;
}

.gwReferralButtonsOffset {
	margin-left: var(--GW-SPACING-XS);
}