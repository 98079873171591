/*extracted from UnderwritingComponent.module.scss*/

.gwTitleSubtitle {
	font-size: var(-GW-FONT-SIZE-XXL);
	line-height: var(--GW-LINE-HEIGHT-XS);
	color: var(--GW-COLOR-TEXT-1);
}

.gwTitleSubtitleOffset {
	margin-bottom: var(--GW-SPACING);
}

.gwPageSectionHeaderRow {
	--gw-page-section-header-border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BORDER);
	border-bottom: var(--gw-page-section-header-border-bottom);
}

.gwReferToUnderwriterForm {
	display: flex;
}

.gwNoteForUnderwriter,
.gwReferralInfo {
	--gw-note-referral-width: 50%;
	--gw-note-referral-padding: 0 var(--GW-SPACING-XXL);
	flex: 1 0 auto;
	width: var(--gw-note-referral-width);
	padding: var(--gw-note-referral-padding);
}

.gwReferralButtons {
	display: flex;
	justify-content: flex-end;
}

.gwReferralButtonsOffset {
	margin-left: var(--GW-SPACING-XS);
}