.form-grid-container {
    display: flex;
    flex-direction: row;
    position: relative;
    .form-grid-group-label {
        flex: 1 1;
    }

    .form-grid-group-panel {
        flex: 2 2;
    }
}

.secondary-title {
    margin: 30px 0 20px 0 !important;
}

.animalToolTip{
    .animalToolTipIcon{
        + div{
            text-transform: none!important;
        }
    }
}
