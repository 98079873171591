.b {
    font-weight: bold;
}

.notificationOverrideMsg{
    border: 4px solid #fff8e6;
    padding: 5px;
    margin-bottom: 10px;
}

.overrideFutureTabLabel{
    margin-top: 5px;
}