/* see QuoteClauseTable.module.scss*/
.quoteTableHeader{
    top: 0;
    border-bottom: 0;
    position: relative;
    display: flex;
    justify-content: space-around;
    /* border-bottom: 8px solid var(--GW-COLOR-LINK-2-ACTIVE);*/
    background-color: var(--GW-COLOR-BACKGROUND-1);
    > div{
        flex: 1 1;
    }
}