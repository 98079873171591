@import "~wni-common-styles/mixins";

.wizardSidebarWrapper {
  padding: 10px 15px;
  text-align: left;
}
.stepParentTitle{
  display: flex;
  justify-content: space-between;
  padding: 8px 0 !important;
  cursor: pointer;
  button{
    border: 0;
    cursor: pointer;
    @include wni-font($font-16, $NeutralUltraDark, $weight-bold);
  }
}
.step {
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: var(--GW-BORDER-RADIUS);
  color: $Primary;
  button{
    font-family: $LocatorLight;
    font-weight: 600 ;
    text-align: left;
  }
  &.hide{
    display: none;
  }
  &.indent{
    padding-left: 5px;
  }
  &.active {
    color: $white;
    background-color: $SecondaryLink;
    position: relative;
  }

  &.active::after {
    content: "";
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    border-right: var(--GW-SPACING-3) solid $gw-white-1;
    border-bottom: var(--GW-SPACING-3) solid transparent;
    border-top: var(--GW-SPACING-3) solid transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.notVisited {
    color:  $gw-grey-light-1;
    cursor: not-allowed;
    &.active{
      color: $white;
      button{
        font-weight: 600;
      }
    }
    button{
      font-weight: 400 ;
    }
    .navigationButton {
      pointer-events: none;
    }
  }

  .navigationButton {
    border: none;
    background: inherit;
    cursor: pointer;
    color: inherit;
    line-height: var(--GW-LINE-HEIGHT-BUTTON);
    padding: var(--GW-LAYOUT-1) var(--GW-LAYOUT-2);
    display: flex;
    .i{
      margin-left: 8px;
      font-size: 18px;
    }
    &.navigationButton:focus{
      outline: none;
    }
  }
}

.warningIssue {
  color: var(--GW-COLOR-WARNING);
}

.errorIssue {
  color: var(--GW-COLOR-ERROR);
}

.stepsNav {
  list-style: none;
  text-align: left;
  padding: 0;
}

ul[class*='stepsNav'] {
  margin: 0;
}

h3.sideBarTitle {
  padding-bottom: var(--GW-SPACING-4);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  margin-bottom: var(--GW-SPACING-4);
}
