/* @import "~gw-capability-quoteandbind-pa-react/pages/Quote/QuotePage.module.scss";*/
/* .quoteStatus {*/
/*   font-size: smaller;*/
/*   color: grey;*/
/*   padding: 0 0.5em;*/
/* }*/
.payAndIssueButton {
    --button-padding-x: 0;
    width: 100%;
    background: var(--GW-BUTTON-FILLED-BACKGROUND-COLOR) !important;
}

.oneTimePaymentButton {
    --button-padding-x: 0;
    width: 140px !important;
}

.hyperlinks {
    text-align: right;
    margin-top: 30px;
}

.alignRight {
    text-align: right;
}


.chevronStyle {
    color: var(--GW-TEXT-COLOR-2);
}

.accordionTitle {
    background-color: transparent;
    color: var(--GW-TEXT-COLOR-2);
    cursor: pointer;
}

.accordionTable > :nth-child(2) > div > div > div{
    margin: var(--GW-LAYOUT-6) 0 !important;
}

.editQuoteRowIcon {
    width: 50% !important;
}

.flexDiv {
    display: flex;
}
