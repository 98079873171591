.vehicleTableContainer {
  margin-bottom: 40px;
}

.yellow {
  color: var(--gw-yellow-2)
}

.green {
  color: var(--gw-green-4)
}

.red {
  color: var(--gw-red-3)
}
