@import "~@jutro/theme/assets/sass/helpers";
@import "~wni-common-styles/mixins";

:root {
  --pageFooter-application-footer-padding: 0 1.5rem;
  --pageFooter-application-footer-width: 100%;
  --pageFooter-3rdParty-logo-size: 20px;
  --pageFooter-3rdParty-logo-spacing: 11px;
}

.pageFooter {
  position: fixed;
    height: $footerHeight;
    background-color: $SecondaryDark;
    padding: var(--pageFooter-application-footer-padding);
    border: none;
    bottom: 0;
    width: 100%;
    z-index: $zindex - 5;
  }
  .pageFooterPos{
    position: relative;
    width: 100%;
    height: $footerHeight;
  }
  .applicationFooter {
    /* position: absolute;*/
    padding: var(--pageFooter-application-footer-padding);
    /* top: 40%;*/
    border: none;
    color: white;
    width: var(--pageFooter-application-footer-width);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    p{
      margin-bottom: 0;
    }
  }

  .logoSpacing {
    width: var(--pageFooter-application-footer-width);
  }

  .cosmosFacebookLogo {
    display: block;
    align-items: center;
    background-image: var(--gw-facebook-logo-image);
    width: var(--pageFooter-3rdParty-logo-size);
    height: var(--pageFooter-3rdParty-logo-size);
    float: right;
    margin-right: var(--pageFooter-3rdParty-logo-spacing);
    
  }
  .cosmosLinkedInLogo {
    display: block;
    align-items: center;
    background-image: var(--gw-linkedin-logo-image);
    width: var(--pageFooter-3rdParty-logo-size);
    height: var(--pageFooter-3rdParty-logo-size);
    float: right;
    margin-right: var(--pageFooter-3rdParty-logo-spacing);
  }
  .cosmosTwitterLogo {
    display: block;
    align-items: center;
    background-image: var(--gw-twitter-logo-image);
    width: var(--pageFooter-3rdParty-logo-size);
    height: var(--pageFooter-3rdParty-logo-size);
    float: right;
    margin-right: var(--pageFooter-3rdParty-logo-spacing);
  }