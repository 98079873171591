:root {
    --gw-confirmation-container-margin:0 auto;
    --gw-confirmation-container-max-width: 90em;
    --gw-success-details-max-width: 60em;
    --gw-confirmation-checkicon-height: 7em;
    --gw-confirmation-checkicon-width:7em;
    --gw-confirmation-checkicon-margin:3em auto;
}

.confirmationContainer {
    margin: var(--gw-confirmation-container-margin);
    max-width: var(--gw-confirmation-container-max-width);
}
.successDetailsContainer {
    margin: var(--gw-confirmation-container-margin);
    max-width: var(--gw-success-details-max-width);
}

.confirmationCheckIcon {
    height: var(--gw-confirmation-checkicon-height);
    width: var(--gw-confirmation-checkicon-width);
    line-height: calc(5.5 * var(--GW-LINE-HEIGHT-MD));
    color: var(--GW-COLOR-SUCCESS);
    background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
    border-radius: 50%;
    margin: var(--gw-confirmation-checkicon-margin);
    text-align: center;
}

.confirmationIcon {
    --gw-policy-renewal-confirmation-icon: 3rem;

    margin-top: var(--GW-SPACING-6);
    font-size: var(--gw-policy-renewal-confirmation-icon);
}

.changesConfirmationTitle {
    text-align: center;
    margin-bottom: var(--gw-confirmation-title-margin-bottom);
}

.summaryDetails {
    padding: var(--GW-SPACING-3);
    text-align: center;
    display: flex;
    flex-direction: column;
}

.removeLinkStyle {
    text-decoration: none;
}

.refNumber {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    display: block;
    text-align: center;
}

.actionContent {
    text-align: center;
}

.viewPolicyButton {
    margin-left: var(--GW-SPACING-6);
}
