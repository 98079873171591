@import "~@jutro/theme/assets/sass/helpers";

:root {
	--gw-notes-margin-top: var(--GW-SPACING-3);
	--gw-notes-margin-right: var(--GW-SPACING-2);
	--gw-note-margin-bottom: var(--GW-SPACING-3);
	--gw-create-note-width: 86%;
}

.stickyNoteIcon {
	margin-top: var(--gw-notes-margin-top);
	margin-right: var(--gw-notes-margin-right);
}

.notesContainer {
	width: 100%;

	label {
		font-weight: var(--GW-FONT-WEIGHT-THIN);
		color: var(--GW-TEXT-COLOR-2);
	}
}

.gwPageHeaderRightPanel {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.gwRowLayout {
	display: flex;
	align-items: stretch;
	align-content: flex-start;
}

.notesButtonRight {
	flex-grow: 2;
}

.searchStyle {
	--gw-padding-search-style: 0px 10px;
	padding: var(--gw-padding-search-style);
}

.notes {
    background: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    box-shadow: var(--GW-SHADOW-2);
	padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);

	div[class*=wizardTitle] {
        padding-top: 0;
    }
}

:global(.themeRoot h2) {
	margin-bottom: 0;
  }

.notesTextStyle {
    width: auto;
}

.displayData {
	padding: var(--GW-LAYOUT-2);
	background: var(--GW-TEXT-COLOR-2);
}

.notesCancel {
	margin-right: var(--GW-LAYOUT-2);
}

.notesSection {
    width: var(--gw-create-note-width);
}

.notesDataContainer {
	div[class*='rt-thead'] {
		display: none;
	}

	div[class*='rt-td']:last-child {
		color: var(--GW-TEXT-COLOR-2);
		margin-bottom: var(--gw-note-margin-bottom);
		text-align: right;

		span {
			background-color: var(--GW-ACCENT-COLOR);
			color: var(--GW-BACKGROUND-COLOR);
			flex: 0 1 auto;
			padding: var(--GW-SPACING-2) var(--GW-SPACING-4);
			border-radius: var(--GW-BORDER-RADIUS);
		}
	}
	margin-bottom: var(--GW-LAYOUT-8);
}

.noteDate {
	--gw-padding-bottom-noteDate: 10px;
	color: var(--GW-TEXT-COLOR-2);
	padding-bottom: var(--gw-padding-bottom-noteDate);
    text-align: left;
}

.noteTopic {
	color: var(--GW-TEXT-COLOR-2);
	margin-bottom: var(--gw-note-margin-bottom);
	text-align: left;
}

.noteDateNormal {
	--gw-padding-bottom-noteDate: 10px;
	color: var(--GW-TEXT-COLOR-2);
	padding-bottom: var(--gw-padding-bottom-noteDate);
    text-align: right;
}

.noteTopicNormal {
	color: var(--GW-TEXT-COLOR-2);
	margin-bottom: var(--gw-note-margin-bottom);
    text-align: right;
}
.noteSubject {
	font-weight: var(--GW-FONT-WEIGHT-EXTRA-BOLD);
}

.noNotesText{
    background: transparent;
    color: var(--GW-TEXT-COLOR-1);
    margin-bottom: var(--GW-LAYOUT-1);
}

.showHideLinkContainer {
	width: 100%;
}

.removeLinkStyle {
    text-decoration: none;
    color: var(--GW-LINK-COLOR);
    [class*='text'] {
		&:hover {
			text-decoration: none;
		}
	}
}


.notesContainerStyle {
	padding-left: var(--GW-LAYOUT-10);
	flex-grow: 1;
}

.searchInputSize {
	--search-input-width: 224px;
	width: var(--search-input-width);
	@include gw-breakpoint-down(tablet) {
		--search-input-width: 180px;
	}
}

.gwTitleStyle {
    padding: var(--GW-LAYOUT-3);
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	margin-bottom: var(--GW-LAYOUT-5);
}
