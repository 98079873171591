
:root{
    --gw-quoteContainer-margin:0 auto;
    --gw-quoteContainer-max-width: 50em;
    --gw-buttonFooter-width: 100%;
    --gw-buttonFooter-margin-top: 15px;
    --gw-buttonFooter-margin-left: 1%;
}

.quoteContainer{
    margin: var(--gw-quoteContainer-margin);
    max-width: var(--gw-quoteContainer-max-width);
}

.buttonFooter{
    width: var(--gw-buttonFooter-width);
    margin-top: var(--gw-buttonFooter-margin-top);
}
.buttonStyle{
    margin-left:var(--gw-buttonFooter-margin-left);
} 
.submissionNext{
    float: right;
}