.checkboxStyles {
  padding-left: 5px !important;
  width: 22px;
  height: 22px;
  i {
      font-size: 20px;
  }
}

.tableContent {
  font-size: var(--GW-FONT-SIZE);
}