.marginSpace {
  margin-top: var(--GW-SPACING);
  margin-bottom: var(--GW-SPACING);
}

.marginTopSpace { 
  margin-top: var(--GW-SPACING);
}

.hideIcon > span > i {
  display: none;
}
