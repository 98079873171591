@import "~wni-common-styles/mixins";

.gridWithLabel {
    grid-column-gap: var(--gw-grid-column-gap);
}

.addPayUsingButton {
    height: 30px;
    min-width: 20px;
}

.primaryPayerMenuButton {
    @include gw-breakpoint-up(desktopSmall) {
        min-width: 20px;
    }
}

.hidden-text {
    span {
        display: none;
    }
}

.planOverrideContainer {
    margin-top: 40px;
}

.displayNone {
    display: none;
}

.primaryPayerInputContainer {
    display: flex;
}

.primaryNamedInsuredEditIcon {
    margin-left: 20px;
}

.primaryPayerContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    flex-direction: row;
    position: relative;
}