
:root {
      --gw-total-value-padding-bot: 10px;
      --gw-transactions-filter-wid: 25%;
      --gw-summary-details-border: 2px;
      --gw-summary-details-margin-bottom: 17px;
      --gw-transactions-filter-max-width: 100%;
      --gw-transaction-filter-width: 280px;
      --gw-summary-details-padding-bottom: 24px;
      --gw-total-value-label: 6px;
      --gw-total-value-margin-top: 10px;
      --gw-transactions-flex-grow: 2;
      --gw-commission-table-margin-top: -20px;
      --gw-total-value-width: 130px;
}

.gwTotalDetails {
      display: flex;
      justify-content: flex-end;
      padding-bottom: var(--gw-total-value-padding-bot);
      margin-top: var(--gw-total-value-margin-top);
      font-weight: var(--font-weight-ultra-bold);
      color: var(--gw-black);

      .gwTotalValueLabel {
            font-size: var(--font-ml);
            color: var(--gw-black);
            display: block;
            padding-right: var(--gw-total-value-label);
            font-weight: var(--font-weight-ultra-bold);
            margin-top: var(--gw-total-value-margin-top);
      }

      span[class*='digitalCurrency'],
      input[class*='currencyInput'],
      div[class*='currencySymbol'] {
            color: var(--gw-black);
            font-size: var(--font-xl);
            font-weight: var(--font-weight-ultra-bold);
            margin: 0;
            padding: 0;
      }

      div[class*='currencySymbol'] {
            line-height: var(--line-height-xxl);
      }

      .gwTotalValue {
            font-size: var(--font-xl);
            font-weight: var(--font-weight-ultra-bold);
            width: var(--gw-total-value-width);
            border: none;
            text-align: right;
            line-height: var(--line-height-xxl);
      }
}

.gwTransactionsContainer {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      padding: 0;
      margin: 0;

      .gwTransactionsInner {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
      }

      div[class*='wizardTitle'] {
            margin-bottom: 0;
      }

      .gwTransactionsContainerHeading {
            flex-grow: var(--gw-transactions-flex-grow);
      }

      .gwTransactionsFilter {
            padding-bottom: 0;
            width: var(--gw-transaction-filter-width);
            max-width: var(--gw-transactions-filter-max-width);

            & > div {
              grid-template-columns: auto auto;
              font-size: var(--base-font-size);
              font-weight: normal;
            }
      }
}

.commissionSummaryTableContainer {
      span[class*='digitalCurrency'] {
            font-weight: var(--font-weight-thin);
            color: var(--gw-gray-dark-4);
      }
}

.gwViewOverall {
      display: flex;
      justify-content: flex-end;
      padding-bottom: var(--gw-total-value-padding-bot);
      margin-top: var(--gw-total-value-margin-top);
      cursor: pointer;
      color: var(--gw-blue-light-8);
      text-decoration: none;
}

.summaryDetails {
      border-bottom: var(--gw-summary-details-border) solid var(--gw-grey-2);
      padding-bottom: var(--gw-summary-details-padding-bottom);

      .summaryDetailsHeading {
            line-height: var(--line-height-m);
            font-size: var(--font-xl);
            font-weight: var(--font-weight-ultra-var(--font-weight-ultra-bold));
            color: var(--gw-dark-blue-1);
      }

      .summaryDetailsSubHeading {
            margin-bottom: var(--gw-summary-details-margin-bottom);
            color: var(--gw-gray-dark-4);
            font-weight: var(--font-weight-ultra-bold);
            font-size: var(--font-ml);
            margin: 0;
            padding: 0;
      }
}

.commissionTableContainer {
      margin-top: var(--gw-commission-table-margin-top);

      span[class*='digitalCurrency'] {
            font-weight: var(--font-weight-thin);
            color: var(--gw-gray-dark-4);
      }

      .negative {
            [class*='currency'] {
                  color: var(--gw-red-3);
            }
      }
}