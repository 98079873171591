@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-box-margin: 0 auto 5px 0px;
  --gw-btn-back-left: -75px;
  --gw-btn-back-top: 50%;
}

.claimDetailsMainContainer {
  display: block;
}

.claimDetails {

  .claimDetailsGrid {
    margin: var(--GW-SPACING-5) 0;
  }

  .gwBackToClaim {
    justify-self: left;
    text-decoration: none;
  }

  .gwPrimaryButton {
    margin-top: var(--GW-SPACING-3);
  }

  .documentIcon {
    cursor: pointer;
  }

  .headerText {
    text-transform: uppercase;
  }

  .claimDetailsContainer {
    padding: var(--GW-LAYOUT-3) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
  }

  .claimDetailsIcon {
    --gw-claim-details-header-icon-border-radius: 50%;

    background: var(--GW-COLOR-SUCCESS);
    color: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--gw-claim-details-header-icon-border-radius);
    padding: var(--GW-SPACING-3);
  }

  .claimServicesContainer {
    .services-grid {
      margin-top: var(--GW-LAYOUT-4);
      padding-bottom: var(--GW-LAYOUT-4);
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }

    .statusIcon {
      --gw-status-icon-border-radius: 50%;

      background: var(--GW-BRAND-COLOR-1);
      padding: var(--GW-SPACING-1);
      border-radius: var(--gw-status-icon-border-radius);
      color: var(--GW-BACKGROUND-COLOR-BODY);
    }

    .vendorLinkContainer {
      margin-top: 0;
    }

    .serviceVendorContent {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      .serviceVendorText {
        color: var(--GW-FOCUS-COLOR);
      }
    }
    .servicePrimaryContact {
      text-decoration: none;
      color: var(--GW-FOCUS-COLOR);
    }
  }

  .claimPaymentsContainer {
    .paymentsGrid {
      margin-top: var(--GW-LAYOUT-4);
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }

    .paymentGridItem {
      align-items: flex-start;
      width: 100%;

      .paymentGridItemLabel {
        padding-top: 0;
      }
    }
    .payments-grid {
      margin-top: var(--GW-LAYOUT-4);
      padding-bottom: var(--GW-LAYOUT-4);
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }

    .statusIcon {
      --gw-status-icon-border-radius: 50%;

      background: var(--GW-BRAND-COLOR-1);
      padding: var(--GW-SPACING-1);
      border-radius: var(--gw-status-icon-border-radius);
      color: var(--GW-BACKGROUND-COLOR-BODY);
    }
  }
}

.gwClaimDetailsNotesWrapper {
  word-break: normal;
}

.claimDetailsTextContainer {
  margin-bottom: var(--GW-LAYOUT-4);
}

.vendorInvolvedTable {
  @include gw-breakpoint-down(phonewide) {
    --table-padding-x: 0;
  }
}

.claimDetailsSection {
  padding-bottom: var(--GW-SPACING-8);
}

h2[class='subTitle'] {
  margin-bottom: var(--GW-SPACING-4);
  padding-bottom: var(--GW-SPACING-5);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.informationNotificationContainer {
  display: flex;
  flex-direction: row;
}

.informationNotificationIcon {
  margin-right: var(--GW-SPACING-3);
}

.flexInherit {
  flex: inherit
}