.lobValuesClass {
    padding-left: var(--GW-SPACING-4);
}

.iconCheckedClass {
    display: inline-block;
}

.iconCheckedClass-selected {
    margin-right: var(--GW-SPACING-2);
}

.itemNameClass {
    display: inline-block;
    padding-left: var(--GW-SPACING-6);
}

.itemNameSelected {
    display: inline-block;
    margin-left: var(--GW-SPACING-2);
}
