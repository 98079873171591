
@import "~@jutro/theme/assets/sass/helpers";

.gwPageHeaderRightPanel {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.iconMargin {
    margin-top: var(--GW-SPACING-2);
}

.searchInputSize {
    --search-input-width: 224px;
    width: var(--search-input-width);
    @include gw-breakpoint-down(tablet) {
        --search-input-tab-width: 180px;
        width: var(--search-input-tab-width);
    }
}

.gwTitleStyle {
    padding: var(--GW-LAYOUT-3) 0;
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.gwPolicyClaimsContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}
