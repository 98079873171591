@import "~@jutro/theme/assets/sass/helpers";
:root {
	--gw-alert-flex: 1;
	--gw-alert-border-radius: 2px;
	--gw-alert-min-height: 38px;
	--gw-alert-max-height: 150px;
	--gw-alert-inner-margin: -16px 0;
	--gw-warning-flex-one: 1;
	--gw-warning-flex-big: 100000;
}

.underwritingContainer {
	margin-top: var(--GW-SPACING);
}

.totalCost {
	--gw-total-cost-padding: var(--GW-SPACING) 0;
	border-top: solid var(--GW-BORDER-WIDTH-THIN) var(--GW-COLOR-BORDER);
	padding: var(--gw-total-cost-padding);
}

.gwWarning {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--GW-SPACING);
	margin-top: var(--GW-SPACING);
	padding: var(--GW-SPACING);
	border: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-ALERT-WARNING-HOVER);
	border-radius: var(--gw-alert-border-radius);
	min-height: var(--gw-alert-min-height);
	font-size: var(--GW-FONT-SIZE);
	background-color: var(--GW-COLOR-ALERT-WARNING);
	border-color: var(--GW-COLOR-ALERT-WARNING-HOVER);
	button[class*='gwWarningIcon'] i {
		flex: 0 auto;
		display: inline-block;
		font-size: var(-GW-FONT-SIZE-XXL);
		color: var(--GW-COLOR-ALERT-WARNING-ACTIVE);
	}
}

.uwIssuesPanel {
	--gw-uwissues-panel-width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: space-between;
	width: var(--gw-uwissues-panel-width);
	.gwWarningContent {
		flex: var(--gw-warning-flex-big) auto;
		.gwWarningContentHeading {
			font-size: 18px;
			font-weight: var(-GW-FONT-WEIGHT-REGULAR);
			line-height: var(--GW-LINE-HEIGHT-XS);
			color: var(--GW-COLOR-TEXT-1);
		}
		.gwWarningContentInfo {
			font-size: var(--GW-FONT-SIZE-LG);
			margin-bottom: 0;
		}
	}
	.gwWarningActionsInfo {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		flex: 1 auto;

		@include gw-breakpoint-down(tablet) {
			padding-top: var(--GW-SPACING-XL);
		}
	}
}

.gwMessageInfo {
	flex: var(--gw-warning-flex-one) auto;
	margin-right: var(--GW-SPACING-XL);
	padding-top: var(--GW-SPACING);
}

.gwWarningIcon {
	flex: 0 auto;
	font-size: var(--GW-FONT-SIZE-LG);
	margin-right: var(--GW-SPACING);
}

.gwInnnerInfo {
	display: flex;
	flex-flow: row wrap;
	align-content: space-between;
	padding-bottom: var(--GW-SPACING);
	margin: var(--gw-alert-inner-margin);
}

.actionBarUW {
	margin-left: var(--GW-SPACING);
}

.gwTitleSubtitle {
	font-size: var(-GW-FONT-SIZE-XXL);
	line-height: var(--GW-LINE-HEIGHT-XS);
	color: var(--GW-COLOR-TEXT-1);
}

.gwTitleSubtitleOffset {
	margin-bottom: var(--GW-SPACING);
}

.gwPageSectionHeaderRow {
	--gw-page-section-header-border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BORDER);
	border-bottom: var(--gw-page-section-header-border-bottom);
}

.gwReferToUnderwriterForm {
	display: flex;
}

.gwNoteForUnderwriter,
.gwReferralInfo {
	--gw-note-referral-width: 50%;
	--gw-note-referral-padding: 0 var(--GW-SPACING-XXL);
	flex: 1 0 auto;
	width: var(--gw-note-referral-width);
	padding: var(--gw-note-referral-padding);
}

.gwReferralButtons {
	display: flex;
	justify-content: flex-end;
}

.gwReferralButtonsOffset {
	margin-left: var(--GW-SPACING-XS);
}
.gwWarningListParagraph {
	/* --gw-warning-list-paragraph-margin: 0px 0px -20px 0px;*/
	/* margin: var(--gw-warning-list-paragraph-margin);*/
	p{
		margin-bottom: 10px;
	}
}
.gwWarningContent {
    ul {
        padding-left: 20px !important;
        li {
            list-style: disc !important;
        }
    }
}

.wishToContinue {
	margin-left: var(--GW-SPACING-XS);
}

.noCapitalize {
	text-transform: none !important;
}
