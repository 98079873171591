@import "~wni-common-styles/mixins";

:root{
    --grid-padding-left: 0.75rem;
    --grid-padding-bottom: 1rem;
    --gw-padding-left-vehicle: 5px;
    --gw-button-padding-left: 20px;
}
.paPolicyChangeWizardContainer {
    div[class*='wizardMain'] {
        --gw-wizard-page-main-width: 100%;
    }
}

.gwInfolabelLabelStyle {
    --info-label-color: var(--gw-grey-light-6);
    --info-label-font-size-m: var(--info-label-font-size);
    --info-label-bg-color-neutral: none;
    --fieldLabel-text-align-left: left;
    --info-label-padding-x-m: none;

}

.gridPaddingLeft{
    padding-bottom: var(--grid-padding-bottom);
    grid-template-columns: 0fr 3fr;
}

.containerStyle {
    padding-left: var(--gw-button-padding-left);
}

.gwTitle {
    font-size: var(--gw-title-font-size);
    font-weight: var(--font-weight-light);
    margin-left: var(--gw-title-margin-left);
    color: var(--gw-grey-dark-90-color);
}

.gwTotalValue {
    font-size: var(--font-xl);
    font-weight: var(--font-weight-ultra-bold);
    border: none;
    text-align: right;
    line-height: var(--line-height-xxl);
}

.gwTotalValueStyle {
    font-size: var(--font-xl);
    font-weight: var(--font-weight-ultra-bold);
    border: none;
    text-align: right;
    line-height: var(--line-height-xxl);
    color: var(--gw-grey);

}

.vehicleModelContainerStyle {
   display: flex;
}

.buttonContainer {
    display: flex;
}

.paVehicleSectionYearIdContainer {
    padding-left: var(--gw-padding-left-vehicle);
}

.vehicleMakeModelLabel{
    --gw-padding-right-vehiclesMakeModel: 0.5rem;
    font-size: var(--font-xlg);
    width: max-content;
    padding-right: var(--gw-padding-right-vehiclesMakeModel);
}

.vehicleYearLabel{
    font-size: var(--font-xlg);
}

.driverNameLabel{
    font-size: var(--font-xlg);
}

.inlineNotificationStyle {
    --gw-inline-margin-top-bottom: 20px 0px;
    margin: var(--gw-inline-margin-top-bottom);
}

.cancelButton {
    float: right,
}

.titleRight {
    clear: both;    
} 
