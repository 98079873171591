@import "~@jutro/theme/assets/sass/helpers";

.gwPageHeader {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.gwPageHeaderRightPanel {
    width: 100%;
}

.gwRowLayout {
    margin-bottom: var(--GW-SPACING-3);
}

.iconMargin {
    margin-top: var(--GW-SPACING-6);
}

.gwClaimsLinkContent {
    text-decoration: none;
}

.claimsContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.gwClaimTableStyle {
    @include gw-breakpoint-down(phonewide) {
        --table-padding-x: 0;
    }
}
