/* @import "~gw-capability-quoteandbind-pa-react/pages/Quote/QuotePage.module.scss";*/
/* .quoteStatus {*/
/*   font-size: smaller;*/
/*   color: grey;*/
/*   padding: 0 0.5em;*/
/* }*/

.wrapEverywhere {
  overflow-wrap: anywhere;
  &> div {
    white-space: break-spaces !important;
  }
}

.maxWidth {
  width: 100% !important;
}