.successDetailsPage {
    --gw-success-details-page-max-width: 90em;
    margin: 0 auto;
    max-width: var(--gw-success-details-page-max-width);
}

.successDetailsContainer {
    --gw-success-details-container-padding-top-bottom: 40px;
    --gw-success-details-container-padding-right-left: 50px;
    padding: var(--gw-success-details-container-padding-top-bottom) var(--gw-success-details-container-padding-right-left);
}

.removeLinkStyle {
    text-decoration: none;
    color: var(--gw-blue-light-8);
    &:hover {
      text-decoration: none;
      color: var(--gw-dark-blue-6);
    }
}

.currencyStyle {
    --gw-currency-style-margin-top: 1rem;
	--fieldComponent-control-readonly-font-weight: var(--font-weight-regular);
    --fieldComponent-control-readonly-font-size: var(--font-ml);
    margin-top: var(--gw-currency-style-margin-top);
}

.noMargin {
    margin: 0 !important;
}