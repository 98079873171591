@import "~wni-common-styles/mixins";
:root {
  --pageHeader-application-header-padding: 0 3rem;
  --pageHeader-application-header-height: 147px;
  --pageHeader-menubar-height: 3.5rem;
  --pageHeader-application-header-search-padding: 0 1.5rem;
  --pageHeader-application-header-width: 100%;
  --pageHeader-application-logo-margin-top-bottom: 50%;
  --pageHeader-application-logo-width: 186px;
  --pageHeader-application-logo-height: 45px;
  --pageHeader-application-logoPortrait-width: 30px;
  --pageHeader-application-logoPortrait-height: 30px;
  --pageHeader-searchbox-padding-bottom: 0px;
  --pageHeader-searchbox-container-width: auto;
  --pageHeader-searchbox-containerPortrait-width: 55rem;
  --pageHeader-quoteAccount-margin-left: 5%;
  --pageHeader-searchbox-width: 40rem;
  --pageHeader-searchbox-height: 4rem;
  --pageHeader-searchboxPortrait-width: 35rem;
  --pageHeader-faq-padding: 0 2rem;
  --pageHeader-auth-menu-padding: 1rem 1.5rem;
  --pageHeader-auth-menu-item-padding: 0.75rem 1rem;
  --pageHeader-auth-status-padding-left: 24px;
  --pageHeader-auth-status-padding-right: 24px;
  --pageHeader-start-newquote-padding-left: 1rem;
}

.pageHeader {
  .applicationHeader {
    position: relative;
    height: var(--pageHeader-application-header-height);
    background-color: $Primary;
    padding: var(--pageHeader-application-header-padding);
    border: none;

    .gwAppHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: var(--pageHeader-application-header-width);
      height: var(--pageHeader-application-header-height);
    }
    .gwHeaderLeft {
      .appLogoContainer {
        display: flex;
        align-items: center;
      }
    }
    .gwHeaderRight {
      display: flex;
      align-items: center;
      div + div{
        margin-left: 10px;
      }
      .startNewQuote {
        margin-left: var(--pageHeader-start-newquote-padding-left);;
      }
    }
  }

  .gwHeaderSearch{
    height: 40px;
    max-width: 380px;
    position: relative;
    .gwHeaderSearchIcon{
      position: absolute;
      right: 5px;
      top: 4px;
      &:active,&:focus,&:hover,&:visited{
        outline: none;
        background: transparent;
        box-shadow: none;
      }
    }
  }
  @include gw-breakpoint-up(tablet) {
    
    .gwHeaderRight {
      div + div{
        margin-left: 10px;
      }
    }
    .producerEngageContainer {
      padding-bottom: var(--gw-main-container-padding);

      >div {
        margin: 0;
      }
    }
  }

  @include gw-breakpoint-only(tablet) {
    .producerEngageContainer {
      padding-bottom: var(--gw-main-container-padding);

      >div {
        margin: 0;
      }
    }
  }
}

.removeIcon {
	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		display: none;
	}
}
