
:root {
  --gw-modal-title-padding-top: 20px;
  --gw-modal-title-padding-bottom: 25px;
  --gw-modal-width: 720px;
  --gw-button-container-margin-top: 20px;
  --gw-cancel-button-margin-right: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gw-button-container-margin-top);
}

.modalWidth {
  width: var(--gw-modal-width);
}

.modalTitle {
  font-weight: var(--font-weight-normal-bold);
  font-size: var(--font-xxl);
  padding-top: var(--gw-modal-title-padding-top);
  padding-bottom: var(--gw-modal-title-padding-bottom);
}

.cancelBtnMargin {
  margin-right: var(--gw-cancel-button-margin-right);
}

.searchTitle {
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  font-size: var(--GW-FONT-SIZE-MD);
  padding-top: var(--gw-modal-title-padding-top);
  padding-bottom: var(--gw-modal-title-padding-bottom);
}
