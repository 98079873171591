@import '~wni-common-styles/mixins';

:root {
	--gw-page-tile-margin: 40px;
	--gw-side-page-category-padding: 10px;
	--gw-side-page-separator-margin-top: 3px;
	--gw-side-page-separator-margin-right: 8px;
	--gw-side-page-separator-height: 18px;
	--gw-side-page-separator-border: 1px;
	--gw-side-page-tile-padding: 12px;
	--gw-info-status-margin-top: 15px;
	--gw-type-lob-margin-top: 15px;
}

.gwPageTitle {
	font-size: var(--GW-FONT-SIZE-XXL);
	line-height: var(--GW-LINE-HEIGHT-MD);
	font-weight: var(--GW-FONT-WEIGHT-LIGHT);
	color: var(--GW-COLOR-TEXT-1);
	margin: 0 0 var(--gw-page-tile-margin) 0;
	.gwPageCategory {
		font-size: 16px;
		font-weight: var(--GW-FONT-WEIGHT-REGULAR);
	}
	.gwPageAssociated {
		color: var(--GW-COLOR-LINK-1);
		text-decoration: none;
	}
}

.gwSidePageTitle {
	display: flex;
	font-size: 16px;
	.gwSidePageCategory {
		padding-right: 10px;
		font-size: 16px;
		color: $PrimaryDark;
	}
	.gwSidePageAssociated {
		color: $SecondaryLink;
		text-decoration: none;
		&:hover {
			color: $SecondaryLinkDark;
		}
	}
	.gwSidePageTitleSeparator {
		margin-top: var(--gw-side-page-separator-margin-top);
		height: var(--gw-side-page-separator-height);
		border: var(--gw-side-page-separator-border) solid var(--GW-COLOR-BACKGROUND-3);
		margin-right: var(--gw-side-page-separator-margin-right);
	}
	.gwSidepageTitle {
		padding-left: var(--gw-side-page-tile-padding);
	}
}

.gwTypeWithLOB {
	margin: 10px 0;
	.syncIcon {
		background: $warningActive;
		font-size: $font-20;
		width: 36px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		border-radius: 50%;
		color: $white;
	}
}

.gwInfoStatus {
	font-size: $font-14;
	align-self: flex-start;
}

.gwHeaderLink {
	cursor: pointer;
	color: $SecondaryDark;
	text-decoration: none;
	&:hover{
		text-decoration: underline;
	}
}

.policyType {
	font-size: 16px;;
    font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);
    color: var(--wni-policy-info-grey);
    padding-left: var(--gw-wizard-side-page-tile-padding);
}