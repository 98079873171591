@import "~wni-common-styles/mixins";
:root {
    --gw-title-font-size: var(--font-xxxl);
    --gw-title-margin-left: -0.05em;
    --gw-divider-margin: 20px;
    --gw-divider-padding: 20px;
    --gw-divider-border: 1px;
    --gw-padding-bottom: 15px;
    --gw-filter-type-title-margin-top: -7px;
    --gw-policy-info-advanced-table-width: 80%;
    --gw-policy-info-quick-table-width: 100%;
    --gw-policy-status-flex: 1 1 1px;
    --gw-policy-status-margin-left: 20px;
    --gw-account-name-link-color: var(--gw-blue-light-8);
    --gw-filter-wrapper-padding-top: 3px;
    --gw-filter-wrapper-padding-left: 5px;
}

.policy{
    .gwRemoveInputLabel {
        grid-template-columns: 0fr auto;
    }
    
    .policyTable {
        /* @include gw-breakpoint-down("tabletPortrait") {*/
        /*     --gw-table-spacing: 0.35rem;*/
        /* }*/
    }
    
    .gwRowLayout {
        display: flex;
        flex-flow: row;
        align-items: stretch;
        align-content: flex-start;
        margin-bottom: var(--line-height-m);
        font-size: var(--base-font-size);
    }

    .gwPoliciesFilterWrapper {
        padding: var(--gw-filter-wrapper-padding-top) 0 0 var(--gw-filter-wrapper-padding-left);

        a {
            span:hover {
              text-decoration: none;
            }
          }
    }

    .gwDisplayNoMessage {
        font-size: var(--base-font-size);
        font-weight: normal;
        color: var(--gw-grey-dark-90-color);
    }

    .gwRowLayoutItem {
        display: flex;
    }
    
    .gwTitle {
        font-size: var(--gw-title-font-size);
        font-weight: var(--font-weight-light);
        margin-left: var(--gw-title-margin-left);
        color: var(--gw-grey-dark-90-color);
    }
    
    .gwProducerCodeDivider {
        display: inline-block;
        border-left: solid var(--gw-divider-border) var(--gw-grey-light-30-color);
        padding-right: var(--gw-divider-padding);
        margin-left: var(--gw-divider-margin);   
        margin-bottom: var(--gw-divider-margin);
    }
    
    .gwFilterTypeTitle {
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
        color:var(--gw-blue-light-8);
        margin-top: var(--gw-filter-type-title-margin-top);
    }
    .gwPolicyInfoAdvancedTable {
        /* width: var(--gw-policy-info-advanced-table-width);*/
    }
    .gwPolicyInfoQuickTable {
        width: var(--gw-policy-info-quick-table-width);
    }
    .gwFilterTypeSection {
        color: var(--gw-grey-light-2);
        padding-bottom: var(--gw-padding-bottom);
        background-color: transparent;
        font-size: var(--base-font-size);
    }

    .iconMargin {
        --gw--margin-top: 6px;
        --gw--margin-right: 10px;
        margin-top: var(--gw--margin-top);
        margin-right: var(--gw--margin-right);
    }

    .gwPolicyTitle {
        font-size: var(--font-xl);
        font-weight: normal;
        line-height: var(--line-height-s);
        color: var(--gw-dark-blue-1);
        margin-bottom: var(--gw-titles-subtitle_offset);
    }

    .gwPageSectionHeaderRow {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        border-bottom: var(--gw-border-solid) solid var(--gw-grey-2);
    }

    .gwAccountHeader {
        display: block;
        text-transform: uppercase;
        font-size: $font-16;
    }
    .gwAccountHeaderItem {
        --gw-account-name-font-size: var(--font-ml);
        display: inline-block;
        font-size: var(--gw-account-name-font-size);
    }
    .gwAccountHeaderLink {
        cursor: pointer;
        color: var(--gw-account-name-link-color);
        text-decoration: none;
    }
    .gwPolicyStatus {
        display: flex;
        align-items: center;
        align-content: center;
        flex: var(--gw-policy-status-flex);
        margin-left: var(--gw-policy-status-margin-left);    
    }
    .gwNoMessage {
        font-size: var(--base-font-size);
        font-weight: normal;
        margin-top: 80px;
        /* background-color: var(--gw-white);*/

      }
    .jut__DataTable__search{
        font-size: 10px;
    }
      
}
