@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-space-for-padding: 6px;
  --gw-container-height: 4.4em;
  --gw-container-width: 1.2em;
  --gw-additional-padding-spacing: 2px;
  --gw-activity-padding-left: 90px;
  --gw-complete-margin-right: 40px;
  --gw-dropdown-activity-width: 11.25rem;
}

.openActivitiesContainer {
  background: var(--GW-COLOR-BACKGROUND-1);
  border-radius: var(--GW-BORDER-RADIUS);
  box-shadow: var(--GW-BOX-SHADOW-OUTER-2);
  padding: var(--GW-SPACING-XL) var(--GW-SPACING-XL) var(--GW-SPACING-XXL);

  div[class*=wizardTitle] {
    padding-top: 0;
  }
}

.addActivityTableGrid {
  padding-left: var(--gw-activity-padding-left);
}

.gwPageHeader {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

}

div[class*='wizardTitle'].gwPageHeader {
  margin-bottom: 0;
}

.completeButton {
  margin-right: var(--gw-complete-margin-right);
}

.gwPageHeaderRightPanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.buttonMarginStyle {
  margin-left: var(--gw-space-for-padding);
}

.gwRowLayout {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-content: flex-start;
}

.gwNoMessage {
  font-size: var(--GW-FONT-SIZE);
  font-weight: normal;
  margin-top: var(--gw-noMessage-margin-top);
  background-color: var(--GW-COLOR-BACKGROUND-1);
}

.gwRowLayoutItem {
  display: flex;
}

.gwColumnLayout {
  margin-right: var(--GW-SPACING-SM);
  margin-left: var(--GW-SPACING-SM);
  width: var(--gw-dropdown-activity-width);

  & > div {
    grid-template-columns: auto auto;
    font-size: var(--GW-FONT-SIZE);
    font-weight: normal;
  }
}

.addNewActivityContainerStyle {
  padding-left: var(--GW-SPACING);
  padding-right: var(--GW-SPACING);
  min-height: var(--gw-container-height);
  min-width: var(--gw-container-width);

  .spanStyle {
    width: 100%;
    text-align: right;
  }

  .gwButtonStyle {
    padding: var(--gw-header-padding);
  }
}

.newActivityGrid {
  div[class^='jut__FieldComponent__fieldComponent'] {
    width: 100%;
  }
  div[class*='jut__FieldLabel__left'] {
    flex: 2 1;
    @include gw-breakpoint-down(tablet) {
      flex: 2.5 1;
    } 
  }
}

.openActivitiesContainer {
  padding: var(--GW-SPACING-XL) var(--GW-SPACING-XL) var(--GW-SPACING-XXL) var(--GW-SPACING-XL);
}

.searchInputSize {
  --gw-seach-input-box-width: calc(7 * var(--GW-SPACING-XXL)); 
  width: --gw-seach-input-box-width; 
  @include gw-breakpoint-down(tablet) {
    width: calc(9 * var(--GW-SPACING-LG));
  } 
}

.gwTitleStyle {
  padding-bottom: var(--GW-SPACING-SM);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BORDER);
}

.addActivityPopUp {
  width: 60%;

  @include gw-breakpoint-down(tablet) {
      width: 80%;
  }

  div[class^='jut__ModalBody__modalBody'] {
      padding: var(--GW-LAYOUT-8) var(--GW-LAYOUT-6) var(--GW-LAYOUT-10) var(--GW-LAYOUT-6);
  }
}

.activityFirstGrid {
  padding-right: var(--GW-LAYOUT-6);
  border-right: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.activitySecondGrid {
  height: fit-content;
}