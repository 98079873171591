.text-center {
  text-align: center;
}

.black {
  color: var(--gw-black)
}

.yellow {
  color: var(--gw-yellow-2)
}

.red {
  color: var(--gw-red-3)
}

.inlineBlock {
  display: inline-block;
}