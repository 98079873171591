@import "../NewQuoteAccountSearch.module.scss";

:root{
    --gw-policyDetailsContainer-margin: 40px 0;
    --gw-policyDetailsContainer-border: 1.5px;
 
}

.existingAccountBorder {
    margin: var(--gw-policyDetailsContainer-margin);
    border: var(--gw-policyDetailsContainer-border) solid var(--gw-grey-2);
}
.quoteContainer {
    margin: 0 auto;
    max-width: var(--gw-quoteContainer-max-width);
}
.accountContainer{
    margin: 0 auto;
    max-width: var(--container-max-width);
}