@import "~wni-common-styles/mixins";

:root {
  --gw-tiles-heading-margin-left: 10px;
  --gw-divider-margin: 20px;
  --gw-divider-padding: 20px;
  --gw-divider-border: 1px;
  --line-margin-bottom: 24px;
  --gw-media-side-bar-top: 10.2rem;
  --gw-noMessage-margin-top: 20px;
}
.landingStyles {
  .tilesHeading {
    margin-left: var(--gw-tiles-heading-margin-left);
  }

  .gwRemoveInputLabel {
    grid-template-columns: 0fr auto;
  }

  .gwRowLayout {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: flex-start;
    margin-bottom: var(--line-margin-bottom);
  }

  .gwRowLayoutItem {
    display: flex;
  }

  .gwTitle {
    font-size: var(--font-xxxl);
    font-weight: var(--font-weight-light);
    color: var(--gw-grey-dark-90-color);
  }

  .gwProducerCodeDivider {
    display: inline-block;
    border-left: solid var(--gw-divider-border) var(--gw-grey-light-30-color);
    padding-right: var(--gw-divider-padding);
    margin-left: var(--gw-divider-margin);
    margin-bottom: var(--gw-divider-margin);
  }

  .gwTitlesSubtitle {
    font-size: $font-20;
    font-weight: bold;
    line-height: 26px;
    color: $PrimaryDark;
    padding: 15px 0;
    font-family: $LocatorRegular;
  }

  .landingContainer {
    .gwRowLayout {
      margin-bottom: 0;
    }

    .wizardTitle {
      margin: 0;
    }
  }

  .mediaSideBarContainer {
    top: var(--gw-media-side-bar-top);
    right: 0;
    position: absolute;
  }
}
