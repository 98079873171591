@import "~wni-common-styles/mixins";

.gw-app-header {
  --gw-app-header-img-width: 451px;
  --gw-app-header-img-height: 56px;

  @include gw-breakpoint-down(tablet) {
    --gw-app-header-img-width: 88px;
  }
}

.gw-app-header-img {
  width: var(--gw-app-header-img-width);
  height: var(--gw-app-header-img-height);
  display: grid;
  cursor: pointer;
  span{
    display: block;
    width: 100%;
    height: 100%;
  }
}