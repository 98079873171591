@import "~wni-common-styles/mixins";

:root {
	--gw-alert-margin-bottom: 40px;
	--gw-alert-border: 1px;
	--gw-alert-border-radius: 2px;
	--gw-alert-min-height: 38px;
	--gw-alert-padding: 16px;
	--gw-message-info-mar-right: 24px;
	--gw-message-info-padding-top: 16px;
	--gw-alert-icon-padding-right: 16px;
	--gw-alert-inner-padding-bottom: 16px;
	--gw-alert-inner-margin: -16px 0;
	--gw-continue-quote-margin-left: 15px;
	--gw-alert-content-flex: 1;
	--gw-alert-padding-left: 10px;
	--gw-alert-message-flex: 1;
	--gw-alert-content-width: 100%;
	--gw-alert-content-spacing-top: -15px;
	--gw-alert-action-buttons-tablet-margin-left: 3rem;
	--gw-alert-action-buttons-tablet-margin-top: 1rem;
}

.gwAlert {
	margin-bottom: var(--gw-alert-margin-bottom);
	border: 1px solid transparent;
	border-radius: var(--gw-alert-border-radius);
	min-height: var(--gw-alert-min-height);
	font-size: var(--base-font-size);

	.gwAlertIcon {
		font-size: var(--font-xlg);
	}
}

.gwAlertInfo {
	background-color: var(--gw-blue-light-9);
	border-color: var(--gw-blue-light-10);
	padding: var(--gw-alert-padding);

	.gwAlertIcon {
		color: var(--gw-blue-light-8);
	}
}

.gwAlertWarning {
	background-color: var(--gw-orange-6);
	border-color: var(--gw-orange-7);
	padding: var(--gw-alert-padding);

	.gwAlertIcon {
		color: var(--gw-orange-5);
	}
}

.gwAlertContent {
	width: var(--gw-alert-content-width);
	padding-left: var(--gw-alert-padding-left);

	.gwAlertContentHeading {
		font-size: var(--font-xl);
		font-weight: var(--font-weight-ultra-bold);
		line-height: var(--line-height-s);
		color: var(--gw-grey-dark-90-color);
		margin: 0;
	}

	.gwAlertContentSubHeading {
		font-size: var(--base-font-size);
		margin-bottom: 0;
	}

	.gwAlertContentInnerHeading {
		font-size: var(--base-font-size);
		margin-bottom: 0;
		margin-top: var(--gw-alert-padding-left);
	}
}

.gwMessageInfo {
	margin-right: var(--gw-message-info-mar-right);
	padding-top: var(--gw-message-info-padding-top);
}

.gwActionsInfo {
	text-align: right;

    @include gw-breakpoint-down(tabletPortrait) {
		text-align: left;
		margin-top: var(--gw-alert-action-buttons-tablet-margin-top);
		margin-left: var(--gw-alert-action-buttons-tablet-margin-left);
    }
}

.gwInnnerInfo {
	padding-bottom: var(--gw-alert-inner-padding-bottom);
	margin: var(--gw-alert-inner-margin);
}

.continueTransaction {
	/* margin-left: var(--gw-continue-quote-margin-left);*/
}

.viewTransaction {
	margin-right: var(--gw-continue-quote-margin-left);
}

.noCapitalize {
	text-transform: none !important;
}
