
:root {
    --gw-header-row-margin-bottom: 10px;
}

.gwPageHeaderRightPanel {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.gwRowLayout {
    display: flex;
    flex-flow: row;
    align-items: stretch;
    align-content: flex-start;
    margin-bottom: var(--gw-header-row-margin-bottom);
}

.driverTable th {
    text-transform: none;
}

.driverTableContainer {
    margin-bottom: 40px;
}

.green {
    color: var(--gw-green-4)
}

.red {
    color: var(--gw-red-3)
}

.driver-bottom-section {
    position: fixed;
    bottom: 70px;
    right: 0;
    width: 65vw;
    padding: 15px 20px;
    height: 70px;
    z-index: 400;
    display: flex;
    justify-content: space-between;
    background: #fff;
}

.skipCoveragesButton {
    float: right;
    margin-top: 20px;
}
