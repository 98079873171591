/* see QuoteClauseTable.module.scss*/
.quoteTableHeaderSection {
    --gw-quote-table-header-section-spacing: 22px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--GW-COLOR-BACKGROUND-1-ACTIVE);
    padding: var(--gw-quote-table-header-section-spacing) 0 var(--gw-quote-table-header-section-spacing) 0;
    position: relative;

    &:not(:last-child) {
        border-right: none;
    }
}
.premiumFont20{
    div{
        font-size: 26px;
        font-weight: 600;
    }
}