@import "~wni-common-styles/mixins";

:root {
  --gw-summary-border-radius: 1rem;
  --gw-summary-padding: 4px 10px;
  --gw-property-header-height: 60px;
  --gw-property-header-page-title-line-height: var(--line-height-xl);
  --gw-property-header-page-title-font-weight: var(--font-weight-extra-light);
  --gw-property-header-page-title-color: var(--gw-grey-dark-90-color);
  --gw-property-header-page-title-margin: 0 0 40px 0;
  --gw-property-header-page-title-width: 85%;
  --gw-override-default-margin: 64px;
  --gw-summary-extra-link-color: var(--gw-blue-light-8);
  --gw-summary-extra-link-margin-bottom: 20px;
  --gw-row-layout-item-margin-left: 20px;
  --gw-row-layout-margin-bottom: 24px;
  --gw-filter-transaction-width: 50%;
  --gw-filter-transaction-width: 40%;
  --gw-filter-transaction-padding-top: 32px;
  --gw-search-transaction-width: 40%;
  --gw-search-transaction-padding-top: 64px;
  --gw-invoice-stream-title-matgin-top: 30px;
  --gw-coverage-top-bottom-margin: 16px;
  --gw-coverage-left-right-margin: 0px;
  --gw-coverage-section-bottom-margin: 16px;
  --gw-coverage-section-bottom-border-width: 1px;
  --gw-coverage-section-bottom-border-color: var(--gw-grey-light-30-color);
  --gw-additional-border-size: 2px;
  --gw-additional-border-color: var(--gw-grey-2);
  --gw-additional-padding-spacing: 10px;
  --gw-additional-left-padding-spacing: 35px;
  --gw-div-container-padding-spacing: 120px;
  --gw-title-font-size: var(--font-xxxl);
  --gw-title-margin-left: -0.05em;
  --gw-property-header-title-color: var(--gw-grey-2);
  --gw-font-weight-property: 200;
  --gw-font-size-transactions: 2.4rem;
  --gw-policy-details-summary-section-top: 1;
  --gw-policy-details-summary-section-bottom: 2;
  --gw-policy-details-summary-section-margin-bottom: 4rem;
}

.typePolicyperiodStatus {
  background-color: var(--gw-blue-light-10);
  color: var(--gw-grey-dark-90-color);
  flex: 0 1 auto;
  font-size: var(--font-xs);
  line-height: var(--line-height-xs);
  padding: var(--gw-summary-padding);
  border-radius: var(--gw-summary-border-radius);
}
.coverageValues {
  @include gw-breakpoint-up(tabletPortrait) {
    height: var(--line-height-m);
  }
}
.gwTransactionsTitle {
  display: inline-block;
        border-left: solid var(--gw-divider-border) var(--gw-grey-light-30-color);
        padding-right: var(--gw-divider-padding);
        margin-left: var(--gw-divider-margin);
        margin-bottom: var(--gw-divider-margin);
}
.gwTransactionsStyle {
    line-height: var(--gw-property-header-page-title-line-height);
    width: var(--gw-property-header-page-title-width);
    font-size: var(--gw-font-size-transactions);
    font-weight: var(--gw-font-weight-property);
    color: var(--gw-grey-dark-90-color);
    line-height: var(--gw-additional-left-padding-spacing);
}
.gwFilterTransaction {
  width: var(--gw-filter-transaction-width);
  margin: 0;
}
.gwSearchTransaction {
  width: var(--gw-search-transaction-width);
  margin: 0;
}
.gwOverrideDefaultMargin {
  margin: 0;
  font-weight: 400;
}
.gwRowLayout {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  margin-bottom: var(--gw-row-layout-margin-bottom);
}
.gwRowLayoutpageTitleHorizontalLine {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  border-bottom: var(--gw-additional-border-size) solid var(--gw-additional-border-color);
  padding: var(--gw-additional-padding-spacing) 0;
  margin-bottom: var(--gw-row-layout-item-margin-left);
}
.gwRowLayoutItem {
  flex: 0 auto;
  display: flex;
  &:not(:first-child) {
    margin-left: var(--gw-row-layout-item-margin-left);
  }
}
.gwSummaryExtraLink {
  color: var(--gw-summary-extra-link-color);
  text-decoration: none;
}
.gwSummaryExtraLinkSpace {
  margin-bottom: var(--gw-summary-extra-link-margin-bottom);
}

.removeLinkStyle {
  text-decoration: none;
  color: var(--gw-blue-light-8);
}

.chevronStyle {
  padding-bottom: var(--gw-invoice-stream-title-matgin-top);
}

.gwCoverages {
  margin: var(--gw-coverage-top-bottom-margin) var(--gw-coverage-left-right-margin);
}
.gwCoverageSection:not(:last-child) {
  margin-bottom: var(--gw-coverage-section-bottom-margin);
  border-bottom: var(--gw-coverage-section-bottom-border-width) solid var(--gw-coverage-section-bottom-border-color);
}
.gwCoverageTitle {
  font-size: var(--font-xl);
  color: var(--gw-grey-dark-90-color);
}
.gwTerms {
  grid-template-columns: 1fr 1fr;
}
.gwTermTitle {
  text-align: right;
}
.billingSummarySeparator {
  --gw-billing-summary-separator-width: 95%;
  --gw-billing-summary-separator-margin-top: 10px;
  width: var(--gw-billing-summary-separator-width);
  margin-top: var(--gw-billing-summary-separator-margin-top);
}
.inputFieldThin {
  --gw-billing-input-field-thin-height: calc(1.5 * var(--GW-SPACING));
  height: var(--gw-billing-input-field-thin-height);
}
.billingCurrencyRightAlign {
  text-align: right;
}
.customerPaymentSummaryStyle {
  color: var(--gw-grey-dark-90-color);
}
.additionalAccordionContainer {
  --gw-accordin-body-padding-top: 20px;
  --gw-additional-accordin-container-margin: 30px 0;
  margin: var(--gw-additional-accordin-container-margin);
  --gw-property-header-page-title-font-size: var(--font-xlg);
  .title {
    border-bottom: var(--gw-additional-border-size) solid var(--gw-additional-border-color);
    padding: var(--gw-additional-padding-spacing) 0;
    margin-bottom: var(--gw-row-layout-item-margin-left);
    font-size: var(--gw-property-header-page-title-font-size);
    font-weight: var(--gw-property-header-page-title-font-weight);
    color: var(--gw-property-header-page-title-color);
    line-height: var(--gw-property-header-page-title-line-height);
    margin: var(--gw-property-header-page-title-margin);
  }
  .Collapse-module {
    padding-top: var(--gw-accordin-body-padding-top);
  }
  .producerOfServiceValueStyle {
    white-space: nowrap;
  }
  .summaryDetailBottom {
    @include gw-breakpoint-down(tabletPortrait) {
      grid-row: var(--gw-policy-details-summary-section-bottom);
    }
  }
  .summaryDetailTop {
    @include gw-breakpoint-down(tabletPortrait) {
      grid-row: var(--gw-policy-details-summary-section-top);
      margin-bottom: var(--gw-policy-details-summary-section-margin-bottom);
    }
  }
  .coverablesTitle {
    font-size: var(--font-xl);
  }
}
