@import '~wni-common-styles/mixins';

:root {
    --gw-policy-info-advanced-table-width: 80%;
    --gw-policy-info-quick-table-width: 100%;
}
.gwPoliciesFilterWrapper {
    a {
        span:hover {
            text-decoration: none;
        }
        }
}

.gwProducerCodeDivider {
    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));
    border-left: solid var(--GW-COLOR-BORDER) var(--GW-BORDER-WIDTH-THIN);
}

.gwFilterTypeTitle {
    cursor: pointer;
    text-decoration: none;
    color:var(--GW-COLOR-LINK-1);
}
.gwPolicyInfoAdvancedTable {
    width: var(--gw-policy-info-advanced-table-width);
}
.gwPolicyInfoQuickTable {
    width: var(--gw-policy-info-quick-table-width);
}
.gwFilterTypeSection {
    color: var(--GW-COLOR-TEXT-1);
}
h1.policyProductName {
    margin-right: var(--GW-SPACING-XS);
    margin-bottom: 0;
}
.policyIcon {
    border-radius: calc(2.5 * var(--GW-SPACING));
    background-color: var(--GW-COLOR-BRAND-3);
    color: $white;
    font-size: var(--GW-FONT-SIZE-LG);
    padding: var(--GW-SPACING-XS);
    margin-right: 0;
}
.accountHeader {
    font-size: var(--GW-FONT-SIZE);
    color: var(GW-COLOR-TEXT-1);
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    margin-bottom: var(--GW-SPACING-XS);
}
.gwPoliciesContainerSection {
    padding: var(--GW-SPACING-XL) var(--GW-SPACING-XL) var(--GW-SPACING-XXL);
}

.gwTitleStyle {
    padding-bottom: var(--GW-SPACING-SM);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BORDER);
  }

.gwPolicyPageTileContainer {
    padding-top: var(--GW-SPACING-XL);
}

.gwRowLayout {
    .policyProductName {
        margin-bottom: 0;
        margin-right: var(--GW-SPACING);
        margin-left: 0;
    }
    padding-top: var(--GW-SPACING-SM);
}

.policyType {
    font-size: $font-16;
    line-height: var(--GW-LINE-HEIGHT-XXL);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    color: var(--wni-policy-info-grey);
}