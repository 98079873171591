.main-phone-icon {
    color: green;
    font-size: 50px;
    margin: 30px auto;
    display: block;
}

.text-align-center {
    text-align: center;
    display: block;
}

.contact-admin-title.text-align-center {
    margin-bottom: 40px;
}
.contact-admin-content {
    line-height: 16px;
}

.phone-number-button {
    margin: 10px auto;
    display: block;
}

.back-claim {
    display: block !important;
    margin: 0 auto;
    text-align: center;
    width: 50px;
    font-weight: bold;
}
