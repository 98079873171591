@import "~wni-common-styles/mixins";

.resultLi {
    border: 1px solid $borderColor;
    list-style-position: inside; 
    padding: 7px;
    & + li{
        border-top: none;
    }
}
.searchBtn {
    min-width: 120px;
}
.selectBtn {
    padding: 0px 50px 0px 10px !important;
    height: unset !important;
}
.resultItem {
    margin-left: 5px;
}
.noDecorator {
    list-style-type: none;
}
