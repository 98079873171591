@import "~@jutro/theme/assets/sass/helpers";

.policyTableContainer {
    tr[class*='jut__Table__row'] {
        &:hover {
            background-color: var(--GW-BACKGROUND-COLOR-BODY);
        }
    }
}

.fnolWizardTitle {
    h3[class*='wizardTitle'] {
        margin-bottom: 0;
    }
}

.policyTable {
    @include gw-breakpoint-down(phonewide) {
        padding: 0;
    }
}
