.lookup-recommend-container{
    position: relative;
    .formInputContent{
        position: relative;
        .formInputButton{
            position: absolute;
            right: 8px;
            top: 4px;
            &:active,&:focus,&:hover,&:visited{
                outline: none;
                background: transparent;
                box-shadow: none;
              }
        }
    }
    .lookup-recommend-content{
        position: absolute;
        top: 42px;
        right: 0;
        left: 33.33%;
        z-index: 201;
        max-height: 260px;
        overflow: auto;
        background: #FFFFFF;
        border-radius: 4px;
        box-shadow: rgba(40, 51, 63, 0.16) 0px 2px 4px 0px;
        &.form-multi-options{
            top: calc( 100% + 5px );
        }
        .recommend-options{
            padding: 8px 16px;
            cursor: pointer;
            &:hover{
                background: #cff2ff;
            }
            &.selected{
                background: #e9f9ff;
            }
        }
        &.left{
            left: 0;
        }
        .multi-options{
            div[class*="checkboxGroup"]{
                padding: 12px 12px 0;
                cursor: pointer;
                div, label, span{
                    cursor: pointer;
                }
                div.jut__CheckboxField__checkbox{
                    .jut__CheckboxField__checkboxElement{
                        width: 22px;
                        height: 22px;
                    }
                }
            }
        }
    } 
}