
.card {
    border: var(--gw-black) solid 1px;
    width: inherit;
    padding: 10px 15px;
}

.cardNew {
    border: var(--gw-gray-dark-5) dashed 1px;
    text-align: center;
    width: inherit;
    cursor: pointer;
}

.cardInvalid {
    @extend .card;
    border: var(--gw-red-1) solid 3px;
}
