@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw-contacts-dropdown-width: 200px;
    --gw-policy-contacts-dropdown-width: 250px;
    --gw-contacts-description-flex: 2;

    --gw-contacts-table-size: 20px;

    --gw-contacts-table-header-height: 50px;
    --gw-contacts-table-header-padding: 9px;

    --gw-contacts-avatar-size: 50px;
}

.gwContactsTableHeader {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    padding: var(--GW-LAYOUT-4);
    display: flex;
    flex-flow: row wrap;

    .gwContactsDescription {
        flex: var(--gw-contacts-description-flex);
        align-self: center;
    }

    .gwContactsDropdownContainer {
        --gw-contacts-dropdown-width: 224px;
        width: var(--gw-contacts-dropdown-width);
        @include gw-breakpoint-down(tablet) {
            --gw-contacts-dropdown-width: 180px;
        }
        & > div {
            grid-template-columns: auto auto;
        }
    }
    .gwColumnLayout{
        --gw-contacts-column-width: 224px;
        margin-left: var(--gw-header-column-margin-left);
        width: var(--gw-contacts-column-width);
        @include gw-breakpoint-down(tablet) {
            --gw-contacts-column-width: 180px;
        }
      }

    .gwPolicyContactsDropdownContainer {
        width: var(--gw-policy-contacts-dropdown-width);

        & > div {
            grid-template-columns: auto auto;
        }
    }
}

.gwAssociatedContactsTableHeader {
    --gw-contacts-header-padding: 10px;

    padding: var(--gw-contacts-header-padding);
    display: flex;
    flex-flow: row wrap;
}

.gwContactsTableContainer {
    .gwContactsTableWrapper {
        display: flex;
        align-items: center;
    }

    .gwInactiveContactAvatar {
        --gw-contacts-avatar-border: 1px;

        background: transparent;
        color: var(--GW-BORDER-COLOR);
        border: var(--gw-contacts-avatar-border) solid var(--GW-BORDER-COLOR);
    }

    .gwContactNameContainer {
        padding-left: var(--gw-contacts-table-header-padding);
    }

    .gwContactName {
        display: inline-block;
        height: var(--GW-LINE-HEIGHT);
        color: var(--GW-LINK-COLOR);
    }

    .gwContactPhoneNumberWrapper {
        padding-top: var(--gw-contacts-table-size);
    }

    .gwContactPhoneNumber {
        color: var(--GW-LINK-COLOR);
        margin-bottom: var(--GW-LINE-HEIGHT);
    }

    .gwContactEmail {
        color: var(--GW-LINK-COLOR);
    }

    .gwContactCommonImage {
        --gw-contacts-common-contact-width: 30px;
        --gw-contacts-common-contact-padding: 5px;

        width: var(--gw-contacts-common-contact-width);
        padding-top: var(--gw-contacts-common-contact-padding);
    }

    .gwContactCommonText {
        white-space: normal;
        text-transform: lowercase;
    }

    .gwInvolvedPoliciesContainer {
        display: flex;
        align-items: baseline;
    }

    .gwInvolvedPolicies {
        padding-right: var(--GW-SPACING-1);
    }

    div[class*='rt-tr'] {
        padding: var(--GW-LAYOUT-1) 0;
    }

    div[class*='rt-td'] {
        align-self: center;
    }
}

.gwAssociatedContactsDescription {
    --gw-contacts-description-padding: 20px;

    padding: var(--gw-contacts-description-padding) 0;
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.associatedContactsHeaderContainer {
    padding-bottom: var(--GW-SPACING-2);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: var(--GW-SPACING-2);
}