.copyFromContainer{
    .dividerLine{
        .dividerFormIcon{
            padding: 0;
            width: 50px;
            margin-top: 10px;
            i{
                font-size: 50px;
                font-weight: 500;
                color: #707070;
            }
            &:hover,&:active,&:visited,&:focus{
                background-color: transparent;
                box-shadow: none;
            }
            &.second{
                margin-left: -40px;
            }
        }
    }
    .copyFromLayout{
        width: 100%;
        /* min-width: 375px;*/
        display: inline-block;
        > div{
            margin-left: 0;
            margin-right: 0;
        }
    }
}